import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import ShimmerJobs from './Shimmer/ShimmerJobs';
import job5 from '../images/Favicon_large-removebg-preview.png'
import { Button, Image } from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';
import { GetAllUsers, createJobApplication, fetchJobData, getAllJobs, getAllFakeJobs } from '../../features/constants/apis';
import { useNavigate } from 'react-router';
import tick from '../images/icon/tick-image.png'
import { useTable, useGlobalFilter, useSortBy, usePagination, useRowSelect, useRowState, useAsyncDebounce } from 'react-table';



const UserCardList = ({ authToken, role, allFakeJobs, ID }) => {


    const navigate = useNavigate();

    const [buttonDisableID, setButtonDisableID] = useState([]);
    const [expandedItem, setExpandedItem] = useState(null);
    const [allEmployers, setAllEmployers] = useState([]);
    const [userData, setUserData] = useState([]);
    const [loading, setLoading] = useState(true); // Add loading state


    // useEffect(() => {
    //     const fetchEmpData = async () => {
    //         setLoading(true);
    //         try {
    //             const data = await GetAllUsers(authToken);
    //             // const employers = data.data.filter(user => user.RoleID === 'Employer');
    //             setUserData(data);
    //             console.log("user Data : ", userData?.data);
    //         } catch (error) {
    //             console.error('Failed to fetch employers:', error);
    //         }
    //         setLoading(false);
    //     }
    //     fetchEmpData();
    // }, [authToken]);

    const toggleAccordion = (idx) => {
        setExpandedItem(expandedItem === idx ? null : idx);
    };

    const notify = async (job, index) => {
        setButtonDisableID([...buttonDisableID, index]);

        const applicationData = {
            'JobID': job.ID,
            'EmpID': job.EmployerID,
            'JobTitle': job.Title,
            'placementID': job.PlacementDriveID,
            'tokenUserID': ID,
        };

        const jobData = await createJobApplication(applicationData, authToken);

        if (jobData?.data === "Job application created successfully!") {
            toast.success('Application Submitted!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light"
            });
        }
    };

    const handleViewJobDetails = (jobID, pdID) => {
        navigate(`/job-details?Job=${jobID}&placement-drive=${pdID}`);
    };

    const getEmployerData = (employerID) => {
        return allEmployers.find(employer => employer.ID === employerID);
    };

    if (loading) {
        // Show ShimmerJobs while loading
        return <ShimmerJobs />;
    }
    // console.log(ID)





    













    return (
        // <ul className="listview image-listview media gap-2 mt-4">
        //     {(Array.isArray(userData) ? userData : []).map((job, idx) => {
        //         console.log("entered in the map loop")
        //         return (
        //             <li className='my-2 custom-accordion pb-2' key={job.ID}>
        //                 <div className="item accordion-header py-3" onClick={() => toggleAccordion(idx)}>
        //                     <div className="imageWrapper in">
        //                         {/* <img src={employer?.Image || job5} alt="" className="imaged w64" /> */}
        //                         <div className="in position-relative">
        //                             <div>
        //                                 <div className="text-muted">{job.Type}</div>
        //                                 <div>{job.FullName}</div>
        //                                 <div className='position-absolute text-muted d-flex align-items-center gap-1'>
        //                                     {expandedItem === idx ?
        //                                         <>
        //                                             <span>Hide</span> <ion-icon name="chevron-up-outline"></ion-icon>
        //                                         </> :
        //                                         <>
        //                                             <span>View More</span> <ion-icon name="chevron-down-outline"></ion-icon>
        //                                         </>
        //                                     }
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="in">
        //                         <div>
        //                             <div className="text-muted">{job.Location}</div>
        //                             {job.CtcFrom} LPA
        //                         </div>
        //                     </div>
        //                     <div className="in">
        //                         <div>
        //                             <div className="text-muted">{job.Openings} opening</div>
        //                             {job.Experience} experience
        //                         </div>
        //                     </div>
        //                     {(role === 'Employer' || role === 'Admin') &&
        //                         <div className='d-flex align-items-center'>
        //                             <Button variant="primary" onClick={() => handleViewJobDetails(job.ID, job.PlacementDriveID)}>View</Button>
        //                         </div>
        //                     }
        //                     {role === 'Candidate' &&
        //                         <div className='d-flex align-items-center'>
        //                             <Button className="d-flex justify-end" variant="primary" disabled={buttonDisableID.includes(idx) || job.Status === "Applied"} onClick={() => notify(job, idx)}>
        //                                 {job.Status === "Applied" ? "Applied" : "Apply"}
        //                             </Button>
        //                             <ToastContainer />
        //                         </div>
        //                     }
        //                 </div>
        //                 <div className='accordion-body' style={{ display: expandedItem === idx ? 'block' : 'none' }}>
        //                     <h4>Job Desccription:</h4>
        //                     {job.Description ?
        //                         <div className="text-black ms-05 mb-1">
        //                             <span className='d-flex justify-content-left align-items-left'>{job.Description}</span>
        //                         </div>
        //                         : <span className='text-muted'>No Description Found</span>
        //                     }
        //                     <h4>Skills Required:</h4>
        //                     {job.Skills ?
        //                         <div className="text-black ms-05 mb-1">
        //                         <span className='d-flex justify-content-left align-items-left'>{job.Skills}</span>
        //                         </div>
        //                         : <span className='text-muted'>No Skills Found</span>
        //                     }
        //                     {/* <div className='pb-2'>
        //                             <h5>General Skills: </h5>
        //                             {job.Skills?.GeneralSkills ?
        //                                 job.Skills.GeneralSkills.map((skill, index) => (
        //                                     <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip">
        //                                         <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={tick}/>{skill}</span>
        //                                     </div>
        //                                 )) : <span className='text-muted'>No General Skill Found</span>
        //                             }
        //                         </div>
        //                         <div>
        //                             <h5>Professional Skills:</h5>
        //                             {job.Skills?.ProfessionalSkills ?
        //                                 job.Skills.ProfessionalSkills.map((skill, index) => (
        //                                     <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip">
        //                                         <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={tick}/>{skill}</span>
        //                                     </div>
        //                                 )) : <span className='text-muted'>No Professional Skill Found</span>
        //                             }
        //                         </div> */}
        //                 </div>
        //             </li>

        //         );
        //     })}
        // </ul>


        <ul className="listview image-listview media gap-2 mt-4">
            {console.log("hello", userData)}
            {userData?.data.map((user, idx) => {
                console.log("heyy", user, idx)
                return (
                    <li className="my-2 custom-accordion pb-2" key={user.ID}>
                        <div className="item accordion-header py-3" onClick={() => toggleAccordion(idx)}>
                            <div className="imageWrapper in">
                                <img
                                    src={user.Image || user} // Fallback image if `user.Image` is null
                                    alt=""
                                    className="imaged w64"
                                />
                                <div className="in position-relative">
                                    <div>
                                        <div className="text-muted">{user.ExperienceLevel}</div>
                                        <div>{user.FullName}</div>
                                        <div className="position-absolute text-muted d-flex align-items-center gap-1">
                                            {expandedItem === idx ? (
                                                <>
                                                    <span>Hide</span> <ion-icon name="chevron-up-outline"></ion-icon>
                                                </>
                                            ) : (
                                                <>
                                                    <span>View More</span> <ion-icon name="chevron-down-outline"></ion-icon>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="in">
                                <div>
                                    <div className="text-muted">{user.Location || "Location not specified"}</div>
                                    {user.CtcFrom ? `${user.CtcFrom} LPA` : "Salary not specified"}
                                </div>
                            </div>
                            <div className='d-flex flex-direction-column w-100'>
                                <h4>Job Description:</h4>
                                {user.Description ? (
                                    <div className="text-black mb-1">
                                        <span className="d-flex justify-content-left align-items-left">{user.Description}</span>
                                    </div>
                                ) : (
                                    <div className=''>
                                        <span className="text-muted">No Description Found</span>
                                    </div>
                                )}
                            </div>

                            {/* {role === 'Employer' || role === 'Admin' ? (
                                <div className="d-flex align-items-center">
                                    <Button variant="primary" onClick={() => handleViewJobDetails(user.ID, user.PlacementDriveID)}>View</Button>
                                </div>
                            ) : role === 'Candidate' ? (
                                <div className="d-flex align-items-center">
                                    <Button
                                        className="d-flex justify-end"
                                        variant="primary"
                                        disabled={buttonDisableID.includes(idx) || user.Status === "Applied"}
                                        onClick={() => notify(user, idx)}
                                    >
                                        {user.Status === "Applied" ? "Applied" : "Apply"}
                                    </Button>
                                    <ToastContainer />
                                </div>
                            ) : null} */}
                        </div>
                        <div className="accordion-body" style={{ display: expandedItem === idx ? "block" : "none" }}>
                            <div className="in">
                                <div>
                                    <div className="text-muted">Open to Work</div>
                                    {(user?.Experience?.length > 0) ? (
                                        <div>
                                            <p>Company: {user.Experience[0].company}</p>
                                            <p>Designation: {user.Experience[0].designation}</p>
                                            <p>Start Year: {user.Experience[0].startYear}</p>
                                            <p>End Year: {user.Experience[0].endYear || 'Present'}</p>
                                        </div>
                                    ) : (
                                        "Experience not specified"
                                    )}
                                </div>
                            </div>
                            <h4>Skills Required:</h4>
                            {user.SkillProfile?.GeneralSkills || user.SkillProfile?.ProfessionalSkills ? (
                                <div className="text-black ms-05 mb-1">
                                    {user.SkillProfile.GeneralSkills?.map((skill, index) => (
                                        <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip">
                                            {skill}
                                        </div>
                                    ))}
                                    {user.SkillProfile.ProfessionalSkills?.map((skill, index) => (
                                        <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip">
                                            {skill}
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <span className="text-muted">No Skills Found</span>
                            )}
                        </div>
                    </li>
                );
            })}
        </ul>



    );
}

const mapStateToProps = ({ auth }) => {
    const { userData, role, fullName, ID, authToken } = auth;

    return {
        userData,
        role,
        fullName,
        ID,
        authToken
    };
}

export default connect(mapStateToProps)(UserCardList);






