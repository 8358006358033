import { React, useEffect} from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'

const CheckStepNumber = ({
    showRegistrationStep,
    showEmpDashboard,
}) => {
    let navigate = useNavigate()
    // const navigateTo = (URL) => {
    // navigate(URL)
    // console.log("URL:", URL);
    // // document.getElementById('modalCloseButton').click()
    // }
    console.log({showRegistrationStep})

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const getCourseID = searchParams.get('id');
    const isEmbedded = searchParams.get('src') === 'embeded';
    const retrievedID = localStorage.getItem('certCourseID');
  
    useEffect(() =>{
        console.log(showRegistrationStep)
        // if (showRegistrationStep === null) {
        //     console.log('in the zeroth if statement')
        //     navigateTo('/')
        // }
        // else 
        if (showRegistrationStep === 0 || showRegistrationStep === 3) {
            console.log('in the first if statement')
            console.log(showRegistrationStep, retrievedID)
            if (retrievedID){
                console.log(retrievedID)
                navigate('/loader')
            }else{
                navigate('/welcome')
            }
        }
        if (showRegistrationStep === -1) {
            navigate('/')
        }
        if (showRegistrationStep === 1) {
            navigate('/complete-profile?step=step1')
        }
        if (showRegistrationStep === 2) {
            navigate('/complete-profile?step=step2')
        }
        if (showRegistrationStep === 5) {
            navigate('/employer-dashboard')
        }
        // else if (showRegistrationStep === 3) {
        //     navigateTo('/complete-profile?step=step3')
        // }
    }, [isEmbedded, showRegistrationStep, showEmpDashboard])
      
    return (
        <div>
            
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const {
      showRegistrationStep
    } = auth
  
    return {
      showRegistrationStep
    }
}

export default connect(mapStateToProps) (CheckStepNumber)
