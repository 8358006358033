import React, { useState, useEffect } from 'react';

const TextAnimation = ({ messages, interval = 4000 }) => {
    const [currentMessageIndex, setCurrentMessageIndex] = useState(0);
    const [fade, setFade] = useState(true);

    useEffect(() => {
        const changeMessage = setInterval(() => {
            setFade(false); // Start fade-out effect
            setTimeout(() => {
                setCurrentMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
                setFade(true); // Start fade-in effect
            }, 500); // 500ms for fade-out duration
        }, interval);

        return () => clearInterval(changeMessage);
    }, [messages, interval]);

    return (
        <div>
            {messages.map((message, index) => (
                <span
                    key={index}
                    style={{
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        transition: 'opacity 0.5s, transform 0.5s',
                        opacity: index === currentMessageIndex && fade ? 1 : 0,
                        transform:
                            index === currentMessageIndex && fade
                                ? 'translateY(0)'
                                : index < currentMessageIndex
                                ? 'translateY(-100%)'
                                : 'translateY(100%)',
                    }}
                >
                    {message}
                </span>
            ))}
        </div>
    );
};

export default TextAnimation;
