import React, { useState, useEffect } from 'react';
import { GetAllVspApplied, UpdateUserVspStatus } from '../../features/constants/apis'; // Correct API functions
import { Row, Button, Modal } from 'react-bootstrap';
import { Link, Navigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import DataTable, { createTheme } from 'react-data-table-component';
import { connect } from 'react-redux';
import Header from '../layout/Header/Header';
import { useNavigate } from 'react-router-dom';


const Vsp = ({ authToken }) => {
    const [allUsers, setAllUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [currnetState, setCurrnetState] = useState();



    const navigate = useNavigate();


    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await GetAllVspApplied(authToken);
                setAllUsers(response.data);  // Set the users data from the response
                console.log("vsp data: ", response.data);
            } catch (error) {
                console.error('Error fetching users:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchData();
    }, [authToken]);

    // Function to handle VSP status update for a user

    const handleUpdateVspStatus = async (userID) => {
        const currentUser = allUsers.find((user) => user.ID === userID); // Find the current user
        if (!currentUser) return;

        const currentStatus = currentUser.VspStatus; // Get the current VspStatus
        const newStatus = !currentStatus; // Toggle the status

        try {
            setLoading(true);
            await UpdateUserVspStatus(authToken, userID); // Call the API to update the status

            // Update the local state to reflect the new status
            setAllUsers((prevUsers) =>
                prevUsers.map((user) =>
                    user.ID === userID ? { ...user, VspStatus: newStatus } : user
                )
            );

            setShowModal(false); // Close the modal after the update
        } catch (error) {
            console.error('Error updating VSP status:', error);
        } finally {
            setLoading(false); // End the loading state
        }
    };


    // const handleUpdateVspStatus = async (userID) => {
    //     // Find the current user using the userID
    //     console.log("VSP ID: ", userID);

    //     const currentStatus = selectedUser.VspStatus; // Get the current status
    //     const newStatus = !currentStatus; // Toggle the VspStatus

    //     try {
    //         setLoading(true);
    //         await UpdateUserVspStatus(authToken, selectedUser.ID); // Call the API without currentStatus

    //         // Update the local state to reflect the new status
    //         setAllUsers((prevUsers) =>
    //             prevUsers.map((user) =>
    //                 user.ID === selectedUser.ID ? { ...user, VspStatus: newStatus } : user
    //             )
    //         );
    //         setSelectedUser((prevUser) => ({ ...prevUser, VspStatus: newStatus }));
    //         setShowModal(false); // Close the modal after successful update
    //     } catch (error) {
    //         console.error('Error updating VSP status:', error);
    //     } finally {
    //         setLoading(false); // End the loading state
    //     }
    // };


    const handelOpenModal = (ID, UserId, VspStatus) => {
        if (VspStatus === true) {
            navigate(`/vps-verification?candidateID=${UserId}&status=${VspStatus}`)
        }
        setSelectedUser({ ID: ID, UserId: UserId, VspStatus: VspStatus });
        setShowModal(true);
    };
    console.log("UserID: ", selectedUser);
    const handelCloseModal = () => {
        setShowModal(false);
        setSelectedUser(null);
    }







    const desiredColumns = [
        {
            name: 'ID',
            selector: (row) => row.UserId, // Get the ID directly
            width: '100px',
            sortable: true,
        },
        {
            name: 'Name',
            selector: (row) => row.FullName, // Correctly map FullName
            width: '250px',
            sortable: true,
        },
        {
            name: 'Phone',
            selector: (row) => row.Phone, // Correctly map Phone number
            width: '260px',
            sortable: true,
        },
        {
            name: 'VSP Status',
            selector: (row) => (row.VspStatus ? 'VSP Verified' : 'Applied'),
            width: '180px',
            wrap: true,
            sortable: true,
        },
        {
            name: 'Remarks',
            selector: (row) => (
                <Button
                    className={row.VspStatus ? 'px-4' : ''}
                    variant={row.VspStatus ? 'outline-primary' : 'primary'}
                    size="sm"
                    onClick={() => handelOpenModal(row.ID, row.UserId, row.VspStatus)}
                // disabled={row.VspStatus}
                >
                    {row.VspStatus ? 'Edit' : 'Pending'}
                </Button>
            ),
            width: '120px',
            margin: "20px"
        },

    ];
    console.log("desiredColumns:", desiredColumns);

    createTheme(
        'solarized',
        {
            background: {
                default: 'transparent',
            },
            context: {
                background: '#CDFFD8',
                text: '#FFFFFF',
            },
            divider: {
                default: '#45A994',
            },
            action: {
                button: 'rgba(0,0,0,.54)',
                hover: 'rgba(0,0,0,.08)',
                disabled: 'rgba(0,0,0,.12)',
            },
        },
        'light'
    );

    return (
        <>
            <Header />
            <div id="appCapsule">
                <div className="section full py-4">
                    <div className="container">
                        <Button variant="primary mb-2">
                            <Link to="/" className="my-2 text-white">
                                <FaArrowLeft style={{ width: '15px', height: '15px', marginRight: '7px' }} />
                                Go to Dashboard
                            </Link>
                        </Button>
                        <h1 className="title text-dark">VSP Applicants</h1>
                    </div>
                </div>
                <div className="section full mt-2 mb-4">
                    <div className="container">
                        <Row className="justify-content-between my-3">
                            <div>
                                {loading ? (
                                    <p>Loading...</p>
                                ) : (
                                    <DataTable
                                        className='justify-content-center'
                                        columns={desiredColumns}
                                        data={allUsers}
                                        striped={true}
                                        theme="solarized"
                                    />
                                )}
                            </div>
                        </Row>
                    </div>
                </div>
            </div>

            {/* confirmation modal is wriiten here  */}
            <Modal show={showModal} onHide={handelCloseModal} centered>
                <Modal.Header closeButton>
                    <Modal.Title>Confirm VSP Status Update</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are your Sure you want to {selectedUser?.VspStatus ? ' mark this user as Pending' : ' verify this user for VSP'}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant='secondary' onClick={handelCloseModal}>Cancel</Button>
                    <Button variant='primary' onClick={() => navigate(`/vps-verification?candidateID=${selectedUser?.UserId}&status=${selectedUser?.VspStatus}`)}>Confirm</Button>

                </Modal.Footer>
            </Modal>
        </>
    );
};

const mapStateToProps = ({ auth }) => {
    const { authToken } = auth;
    return {
        authToken,
    };
};

export default connect(mapStateToProps)(Vsp);
