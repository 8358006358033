
export const generalSkillOptions = [
    { value: "Communication Skills", label: "Communication Skills" },
    { value: "Problem Solving", label: "Problem Solving" },
    { value: "Teamwork", label: "Teamwork" },
    { value: "Time Management", label: "Time Management" },
    { value: "Adaptability", label: "Adaptability" },
    { value: "Critical Thinking", label: "Critical Thinking" },
]

export const professionalSkillOptions = [
    { value: "Project Management", label: "Project Management" },
    { value: "Data Analysis", label: "Data Analysis" },
    { value: "Presentation Skills", label: "Presentation Skills" },
    { value: "Research Skills", label: "Research Skills" },
    { value: "Financial Management", label: "Financial Management" },
    { value: "Technical Proficiency", label: "Technical Proficiency" },
]