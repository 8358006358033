import React from 'react'
import { Row, Col, Button } from 'react-bootstrap'
import 'react-toastify/dist/ReactToastify.css';
import { Link } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import ServerSide from '../layout/ServerSide/ServerSide.js';

function CslrCandidateList() {
    return (
        <div id="appCapsule">
            <section className="section full pt-4">
                <div className='container'>
                    <Link to="/" type="submit" className='my-2 text-white'>
                        <Button variant="primary mb-2"><FaArrowLeft style={{ width: '15px', height: '15px', marginRight: "7px" }} />Go to Dashboard
                        </Button>
                    </Link>
                    <Row>
                        <Col className='d-flex justify-content-between'>
                            <div>
                                <div className="d-flex align-items-center gap-2">
                                    <h1 className="title center text-dark" style={{ margin: "0" }}>Candidate Listing</h1>
                                </div>
                                <div className="section-title ps-0 text-dark">All Registered Candidates</div>
                            </div>
                        </Col>
                    </Row>
                    <ServerSide/>
                </div>
            </section>
        </div >
    )
}

export default (CslrCandidateList)
