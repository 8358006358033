import React, { useEffect, useState, useRef } from 'react'
import { connect } from 'react-redux'
import { Card, Button, Form, InputGroup, Modal, Image, Row, Col } from "react-bootstrap";
import Select from 'react-select';
import { generalSkillOptions, professionalSkillOptions } from '../../features/constants/skillsData';
import { Navigate, useLocation, useNavigate } from 'react-router';
// import userPhoto from '../images/user-profile.webp';
import logoPhoto from '../images/Favicon_large-removebg-preview.png';
import userPhoto from '../images/profile-placeholder.jpeg'
import { GetAllApplicationsOfDrive, GetCandidateByID, UpdateUserProfile, deleteCandidateEducationRecord, deleteCandidateExperienceRecord, fetchAllJobRoles, fetchAllJobRolesSkills, getRemovedSkills, handelOpenToWork, postUserImage, shortlistApplicant, UserJoinStatus } from '../../features/constants/apis';
import AddNewEducation from './Modals/AddNewEducation';
import { FaArrowLeft, FaPen, FaPlus } from 'react-icons/fa';
import AddNewExperience from './Modals/AddNewExperience';
import axios from 'axios';
import { RiDeleteBin6Line } from 'react-icons/ri';
import UpdateEducationModal from './Modals/UpdateEducationModal';
import UpdateExperienceModal from './Modals/UpdateExperienceModal';
import { FaUpload } from 'react-icons/fa';
import FileUploadWithProgress from './FileUploadWithProgress';
import tick from '../images/icon/tick-image.png';
import gayTick from '../images/icon/tick-image-gray.png';
import { Link } from 'react-router-dom';
import GetVspModal from './Modals/GetVspModal';
import ProgressBar from 'react-bootstrap/ProgressBar';
import TextAnimation from './MacroComponents/TextAnimation';
import { colors } from '@mui/material';
import ScopehaiLogo from '../images/scopehai-favicon.png'
import StarIcon from '../images/star.png'

const CandidateProfile = ({ fullName, role, authToken, ID }) => {

    const [openToJoin, setOpenToJoin] = useState(Boolean);
    const [openToSwitch, setOpenToSwitch] = useState(Boolean);
    const [expectedCTC, setExpectedCTC] = useState("");
    const [currentCTC, setCurrentCTC] = useState('');
    const [userCurrentStatus, setUserCurrentStatus] = useState('');




    const [cvUploaded, setCvUploaded] = useState(false); // State variable to track CV upload
    const [downloadCVUrl, setDownloadCVUrl] = useState('');
    // photo upload
    const [uploadedImage, setUploadedImage] = useState('');
    const [fileUploaded, setFileUploaded] = useState(false);
    const [previewImage, setPreviewImage] = useState(null);
    const [imageSet, setImageSet] = useState(false);
    const fileInputRef = useRef(null);


    const [userInfo, setUserInfo] = useState([])
    const [isEditingProfile, setIsEditingProfile] = useState(false)

    const [userDomain, setUserDomain] = useState('')

    const [userEduction, setUserEducation] = useState([]);
    const [userExperience, setUserExperience] = useState([])

    const [jobRoles, setJobRoles] = useState([])

    // VSP  modal
    const [showGetVspModal, setShowGetVspModal] = useState(false);
    const [vspModalShow, setVspModalShow] = useState(false);

    // Add new Education modal
    const [eduModalShow, setEduModalShow] = useState(false);
    const [expModalShow, setExpModalShow] = useState(false);

    // Delete modals
    const [modalShow, setModalShow] = useState(false);
    const [itemToDelete, setItemToDelete] = useState(null);
    const [eduIdToDeleted, setEduIdToDeleted] = useState(null)
    const [expIdToDeleted, setExpIdToDeleted] = useState(null)
    const [modalTitle, setModalTitle] = useState('');
    const [modalMessage, setModalMessage] = useState('');

    // Update Education Modal
    const [updateEduModalShow, setUpdateEduModalShow] = useState(false)
    const [editabelEducation, setEditableEducation] = useState([])

    // Update Experience Modal
    const [updateExpModalShow, setUpdateExpModalShow] = useState(false)
    const [editableExperience, setEditableExperience] = useState([])


    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const candidateID = searchParams.get('candidate');
    const jobID = searchParams.get('job');


    // Edit Bio
    const [editableUserFullName, setEditableUserFullName] = useState('');
    const [editableUserDomain, setEditableUserDomain] = useState('');
    const [editableDescription, setEditableDescription] = useState('');
    const [editableGenSkills, setEditableGenSkills] = useState([]);
    const [editableProSkills, setEditableProSkills] = useState([]);

    // shortlist candidate index
    const [currentApplicantIndex, setCurrentApplicantIndex] = useState(0);

    // job applicants
    const [jobApplicants, setJobApplicants] = useState([])
    const [perticularApplication, setPerticularApplication] = useState([])


    // removed skills
    const [removedGeneralSkills, setRemovedGeneralSkills] = useState([])
    const [removedProfessinalSkills, setRemovedProfessinalSkills] = useState([])

    // progressbar
    const [profileProgressCount, setProfileProgressCount] = useState(0)

    // skills lis
    const [generalSkillsList, setGeneralSkillsList] = useState([])
    const [proSkillsList, setProSkillsList] = useState([])

    useEffect(() => {
        const removedSkillsData = async () => {
            const data = await getRemovedSkills(authToken, candidateID)
            setRemovedGeneralSkills(data.generalSkills)
            setRemovedProfessinalSkills(data.professionalSkills)
        }

        removedSkillsData()
    }, [candidateID])

    useEffect(() => {
        const UserData = async () => {
            const data = await GetCandidateByID((candidateID ? candidateID : ID), authToken)
            if (role === 'Candidate') {
                setUserInfo(data.data);
                setUserEducation(data.data?.Education);
                setEditableEducation(data.data?.Education);
                setUserExperience(data.data?.Experience);
                setUserDomain(data.data?.CareerProfile.domain);
                setCurrentCTC(data.data?.CareerProfile.expectedCtc);
                setExpectedCTC(data.data?.CareerProfile.incomeCTC);
                console.log("incoming user data expectedCtc: ", data.data?.CareerProfile.expectedCtc)
                console.log("incoming user data incomeCTC: ", data.data?.CareerProfile.incomeCTC)
            }

            console.log("incoming user data Joining Satus: ", data.data?.JoiningStatus);
            if (data.data.JoiningStatus === 'join') {
                console.log("get into join status true");
                setOpenToJoin(true);
                setOpenToSwitch(false);
            }
            else if (data.data.JoiningStatus === 'switch') {
                console.log("get into switch status true");
                setOpenToJoin(false);
                setOpenToSwitch(true);
            }
            else {
                console.log("get into null status true");
                setOpenToJoin(false);
                setOpenToSwitch(false);
            }


            console.log("incoming user data: ", data.data)
            console.log("incoming user data: ", data.data.JoiningStatus)
            if (data.data.JoiningStatus === "join") {
                console.log("if open to  join working!!!")
                setOpenToJoin(data.data.JoiningStatus);
                setOpenToSwitch(false);

            }
            else if (data.data.JoiningStatus === "switch") {
                console.log("else open to  switch working!!!")
                setOpenToJoin(false);
                setOpenToSwitch(data.data.JoiningStatus);
            }
            else {
                console.log("else open to  nothing selected value false")
                setOpenToJoin(false);
                setOpenToSwitch(false);
            }
        }
        // };
        UserData();
    }, [ID, authToken, role, candidateID, cvUploaded]);





    // This function will be called when the CV is uploaded successfully
    const handleCVUploadSuccess = () => {
        setCvUploaded(prev => !prev);

    };



    // jobRole

    useEffect(() => {
        const fetchJobRoles = async () => {
            const data = await fetchAllJobRoles(authToken);
            setJobRoles(data.data)

        }
        fetchJobRoles()
    }, [authToken])

    useEffect(() => {
        if (userInfo.CareerProfile && jobRoles.length > 0) {
            const domainValue = userInfo.CareerProfile.domain;
            const matchingJobRoles = jobRoles.find(jobRole => jobRole.ID == domainValue);
            // setUserDomain(matchingJobRoles.Title)
        }
    }, [userInfo.CareerProfile, jobRoles]);


    // skills from job roles
    const [jobRolesSkills, setJobRolesSkills] = useState({ GeneralSkills: [], ProfessionalSkills: [] })

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchAllJobRolesSkills(authToken);
            setJobRolesSkills(data.data)
        }
        fetchData()
    }, [authToken]);

    useEffect(() => {
        if (jobRolesSkills) {
            const generalSkillOptions = jobRolesSkills?.GeneralSkills.map(skill => ({
                value: skill,
                label: skill,
            }));

            const professionalSkillOptions = jobRolesSkills?.ProfessionalSkills.map(skill => ({
                value: skill,
                label: skill,
            }));
            setGeneralSkillsList(generalSkillOptions)
            setProSkillsList(professionalSkillOptions)
        }
    }, [jobRolesSkills])

    const refetchUserData = async () => {
        const data = await GetCandidateByID(ID, authToken);
        setUserEducation(data?.data?.Education);
        setUserExperience(data?.data?.Experience);
    };

    // Edit user profile
    const handleProfileEdit = (loadType) => {
        setEditableUserFullName(userInfo?.FullName || '');
        setEditableUserDomain(userInfo?.CareerProfile?.domain || '');
        setEditableDescription(userInfo?.Description || '');

        const defaultGenSkills = generalSkillsList?.filter(option =>
            userInfo?.SkillProfile?.GeneralSkills.includes(option.value)
        );
        setEditableGenSkills(defaultGenSkills);

        const defaultProSkills = proSkillsList?.filter(option =>
            userInfo?.SkillProfile?.ProfessionalSkills.includes(option.value)
        );
        setEditableProSkills(defaultProSkills);

        if (loadType !== 'firstLoad') {
            if (role === 'Candidate') {
                setIsEditingProfile(true)
            }
        }
    }

    const EditUserProfile = async (userData, actionType) => {
        const updateProfile = await UpdateUserProfile(ID, userData, authToken)
        console.log("user Data: ", userData);
        if (updateProfile.status == '200' && actionType === 'doLater') {
            navigate('/candidate-dashboard')
        }
        const data = await GetCandidateByID(ID, authToken)
        if (role === 'Candidate') {
            setUserInfo(data.data);
            setUserEducation(data.data?.Education);
            setUserExperience(data.data?.Experience);
        }
    }

    const handleSaveEditedProfile = (actionType) => {

        let status = '';
        if (openToJoin === true) {
            status = 'join'
            console.log("status changed to join");
        }
        else if (openToSwitch === true) {
            status = 'switch'
            console.log("status changed to switch");
        }
        else {
            status = 'null'
            console.log("status changed to null");

        }
        console.log("final status which is setting in usercurent status: ", status);

        const editedUserInfo = {
            'name': editableUserFullName,
            'domain': editableUserDomain,
            'generalSkills': editableGenSkills.map(skill => skill.value),
            'professionalSkills': editableProSkills.map(skill => skill.value),
            'description': editableDescription,
            'JoiningStatus': status,
            'CareerProfile': {
                'degree': userInfo?.CareerProfile?.degree,
                // 'domain': userInfo.CareerProfile?.domain,
                'domain': editableUserDomain,
                'education': userInfo.CareerProfile?.education,
                'experience': userInfo.CareerProfile?.experience,
                'incomeCTC': currentCTC || userInfo.CareerProfile?.incomeCTC || null,
                'expectedCtc': expectedCTC,
            },
            'ProfileProgress': profileProgressCount,

        }
        console.log("editedUserInfo", editedUserInfo);
        console.log("userInfo", userInfo);


        if (actionType === 'doLater') {
            EditUserProfile(editedUserInfo, actionType)
        } else {
            EditUserProfile(editedUserInfo)
        }



        setIsEditingProfile(false)
    }

    const handleProfileCancelEdit = () => {
        setEditableUserFullName(userInfo?.FullName)
        setEditableUserDomain(userInfo?.CareerProfile?.domain)
        setEditableDescription(userInfo?.Description)
        setIsEditingProfile(false)
    }

    const uploadRefImage = async (filePath) => {
        setFileUploaded(true);
        const formData = new FormData();
        formData.append('inputFile', filePath);
        try {
            const res = await axios.post('https://api.allpos.in/applications/upload/scopehai', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });
            setUploadedImage(res.data.data);
            setFileUploaded(false);
            return res.data.data; // Return the uploaded image URL
        } catch (err) {
            console.error('Error uploading file:', err);
            setFileUploaded(false);
            throw err;
        }
    };

    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setPreviewImage(URL.createObjectURL(file));
            try {
                const imageUrl = await uploadRefImage(file); // Wait for the image URL
                await handleSetImage(imageUrl); // Call handleSetImage with the image URL
            } catch (error) {
                console.error('Error handling image change:', error);
            }
        }
    };

    const handleSetImage = async (imageUrl) => {

        const imageUrlObject = {
            image: imageUrl
        };

        await postUserImage(ID, imageUrlObject, authToken);

        setUserInfo(prevUserInfo => ({
            ...prevUserInfo,
            Image: imageUrl // Use the provided image URL
        }));

        setImageSet(true);
        setPreviewImage(null);

        fileInputRef.current.value = '';
    };


    // Deleting Education and Experience
    const handleShowDeleteModal = (item, eduID = null, expID = null) => {

        if (item === 'education') {
            setModalTitle("Delete Education");
            setModalMessage("Are you sure you want to delete this record? This action can not be undone.")
            setItemToDelete(item);
            setEduIdToDeleted(eduID);
        }
        if (item === 'experience') {
            setModalTitle("Delete Experience");
            setModalMessage("Are you sure you want to delete this record? This action can not be undone.")
            setItemToDelete(item);
            setExpIdToDeleted(expID);
        }
        setModalShow(true);
    }

    const fetchUserData = async () => {
        const data = await GetCandidateByID(ID, authToken);
        if (role === 'Candidate') {
            setUserInfo(data.data);
            setUserEducation(data.data?.Education);
            setUserExperience(data.data?.Experience);
        }
    };

    const handleDelete = async () => {
        if (itemToDelete === 'education') {
            await deleteCandidateEducationRecord(authToken, ID, eduIdToDeleted)
        }
        else if (itemToDelete === 'experience') {
            await deleteCandidateExperienceRecord(authToken, ID, expIdToDeleted)
        }

        setModalShow(false)
        fetchUserData()
    }

    const handleCloseGetVspModal = () => {
        setShowGetVspModal(false);
    };

    // VSP MODAL
    // const handleShowGetVspModal = () => {
    //     setUpdateEduModalShow(true)

    // }

    // Edit Education
    const handleShowEduUpdateModal = (education) => {
        setUpdateEduModalShow(true)
        setEditableEducation(education)

    }

    // Edit Experience
    const handleShowExpUpdateModal = (experience) => {
        setUpdateExpModalShow(true)
        setEditableExperience(experience)
    }


    // upload CV
    const [uploadProgress, setUploadProgress] = useState(0);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onprogress = (event) => {
                if (event.loaded && event.total) {
                    const progress = (event.loaded / event.total) * 100;
                    setUploadProgress(progress);
                }
            };
            reader.onloadend = () => {
                setUploadProgress(100);
                setTimeout(() => setUploadProgress(0), 2000); // Reset after 2 seconds
            };
            reader.readAsDataURL(file);
        }
    };

    // candidates
    useEffect(() => {
        const fetchPDApplications = async () => {
            const data = await GetAllApplicationsOfDrive(authToken)
            setJobApplicants(data.filter(application => application?.EmployerID === ID))

            //get perticular application
            const filteredPerticularApplication = data.filter(application => (application?.JobID === parseInt(jobID)) && (application?.CandidateID === parseInt(candidateID)) && (application?.EmployerID === ID))

            setPerticularApplication(filteredPerticularApplication);

        }
        fetchPDApplications();
    }, [authToken, ID, candidateID, jobID]);



    const navigate = useNavigate();
    const handleLater = (event) => {
        const form = event.currentTarget;
        if (openToSwitch) {
            console.log("open to switch validation 1")
            if (!expectedCTC) {
                console.log("open to switch validation 2")
                setValidated(true);
                return;
            }
            if (!currentCTC) {
                console.log("open to switch validation 3")
                setValidated(true);
                return;
            }
            else {
                setValidated(false);
            }
        }
        if (openToJoin || openToSwitch) {
            if (!expectedCTC) {
                setValidated(true);
                return;
            }
            if (!expectedCTC) {
                setValidated(true);
                return;
            }
        }
        setValidated(false);


        handleSaveEditedProfile('doLater')
    }

    // shortlist and next
    const handlleShortlistAndNext = async () => {
        setCurrentApplicantIndex(prevIndex => {
            const newIndex = prevIndex + 1;
            if (newIndex < jobApplicants.length) {
                const nextCandidateID = jobApplicants[newIndex].CandidateID;
                const nextJobID = jobApplicants[newIndex].JobID;
                navigate(`/candidate-profile?candidate=${nextCandidateID}&job=${nextJobID}`);
            } else {
                setCurrentApplicantIndex(0);
            }
            return newIndex;
        });
        await shortlistApplicant(authToken, jobID, candidateID)
    };
    const viewNextApplicant = async () => {
        setCurrentApplicantIndex(prevIndex => {
            const newIndex = prevIndex + 1;
            if (newIndex < jobApplicants.length) {
                const nextCandidateID = jobApplicants[newIndex].CandidateID;
                const nextJobID = jobApplicants[newIndex].JobID;
                navigate(`/candidate-profile?candidate=${nextCandidateID}&job=${nextJobID}`);
            } else {
                setCurrentApplicantIndex(0);
            }
            return newIndex;
        });
    };

    const calculateProfileProgress = () => {
        let progressCount = 0
        if (editableUserFullName?.trim().length > 3) {
            progressCount = progressCount + 1
        }
        if (editableUserDomain?.length > 0) {
            progressCount = progressCount + 1
        }
        if (editableDescription?.trim().length > 10) {
            progressCount = progressCount + 1
        }
        if (editableGenSkills?.length > 0) {
            progressCount = progressCount + 1
        }
        if (editableProSkills?.length > 0) {
            progressCount = progressCount + 1
        }
        if (userInfo?.CV?.length > 0) {
            progressCount = progressCount + 1
        }
        if (userInfo?.Image?.length > 0) {
            progressCount = progressCount + 1
        }

        const progressPercent = Math.round((progressCount / 7) * 100)
        setProfileProgressCount(progressPercent)
        console.log("progress bar count: ", progressPercent);
    }

    useEffect(() => {
        calculateProfileProgress()
    }, [editableUserFullName, editableUserDomain, editableDescription, editableGenSkills, editableProSkills, userInfo.CV, userInfo.Image, userInfo])

    useEffect(() => {
        if (userInfo) {
            handleProfileEdit('firstLoad')
        }
    }, [userInfo, generalSkillsList, proSkillsList])

    const messages = [
        'Use your full name for a professional profile.',
        'Showcase skills to stand out.',
        'Upload a photo to make a strong impression.',
        'A detailed CV boosts your chances.',
        'Add education to show qualifications.',
    ];

    ///////////////////////////////////////////////////// radio toggle 


    const handleOpenToJoin = (event) => {
        const newValue = event.target.checked;
        setOpenToSwitch(false);
        setOpenToJoin(newValue);
        // console.log("open to join :", newValue);
    };
    console.log("open to switch:", openToSwitch);
    const handleOpenToSwitch = (event) => {
        const newValue = event.target.checked;
        // console.log("what is the vlaue: ", newValue);
        setOpenToJoin(false);
        setOpenToSwitch(newValue);
        // console.log("open to switch:", newValue);
    }
    // console.log("open to join :", openToJoin);

    // Handlers for input changes
    const handleExpectedCTCChange = (e) => setExpectedCTC(e.target.value);
    const handleCurrentCTCChange = (e) => setCurrentCTC(e.target.value);

    // console.log("expected CTC: ", expectedCTC);
    // console.log("current CTC: ", currentCTC);

    // console.log("user data after saving in state: ", userInfo);
    const setUserJoinStatus = () => {
        const newRegisteredData = {
            'degree': userInfo?.CareerProfile?.degree,
            'domain': userInfo.CareerProfile?.domain,
            'education': userInfo.CareerProfile?.education,
            'experience': userInfo.CareerProfile?.experience,
            'incomeCTC': currentCTC || userInfo.CareerProfile?.incomeCTC || null,
            'expectedCtc': expectedCTC,
        }
        // console.log("updated data: ", newRegisteredData);
        return newRegisteredData
    }
    let userID = ID;

    const [validated, setValidated] = useState(false);

    const handleUserStatus = (event) => {

        const form = event.currentTarget;
        if (openToSwitch) {
            console.log("open to switch validation 1")
            if (!expectedCTC.trim()) {
                console.log("open to switch validation 2")
                setValidated(true);
                return;
            }
            if (!currentCTC.trim()) {
                console.log("open to switch validation 3")
                setValidated(true);
                return;
            }
            else {
                setValidated(false);
            }
        }

        event.preventDefault()
        console.log(setUserJoinStatus())
        // setSendOtpButton(true)
        UserJoinStatus({ ...setUserJoinStatus(), userID })
    }





    return (
        <div id="appCapsule">
            <div className='container candidate-profile' style={{ marginBottom: '60px' }}>
                {(role === 'Employer' || role === 'Admin') &&
                    <Link to="/payments" type="submit" className='my-2 text-white'><FaArrowLeft style={{ width: '15px', height: '15px', marginRight: "7px" }} /><Button variant="primary mb-2">Go to Payments</Button></Link>
                }
                <div className="section mt-2">
                    <div className="profile-head">
                        <div className="avatar d-flex flex-column align-items-center">
                            <label htmlFor="fileUpload" class="cursor-pointer">
                                <img
                                    src={previewImage || userInfo.Image || userPhoto}
                                    alt="avatar"
                                    className="imaged w64 rounded profile-pic"
                                />
                            </label>
                            {role === 'Candidate' &&
                                <>
                                    <Form.Control
                                        id='fileUpload'
                                        type="file"
                                        accept='image/*'
                                        custom
                                        label='Choose Image'
                                        onChange={handleImageChange}
                                        className="btn btn-outline-primary btn-sm cursor-pointer "
                                        style={{ padding: '0.2rem 0.2rem', lineHeight: '1.5', display: 'none' }}
                                        ref={fileInputRef}
                                    />


                                    {fileUploaded ? (
                                        <span className='text-nowrap'>uploading...</span>
                                    ) : (
                                        <label htmlFor="fileUpload" className='cursor-pointer text-muted' style={{ whiteSpace: 'nowrap', fontSize: '12px' }}>Edit</label>
                                    )}

                                </>
                            }
                        </div>
                        <div className="in w-100">
                            {isEditingProfile ?
                                <>
                                    <div className='d-flex name-title justify-content-between'>
                                        <InputGroup className="w-50">
                                            <InputGroup.Text>
                                                <ion-icon name="person-outline"></ion-icon>
                                            </InputGroup.Text>
                                            {/* <Form.Group className="w-50"> */}
                                            <Form.Control
                                                required
                                                type="text"
                                                onChange={(e) => setEditableUserFullName(e.target.value)}
                                                value={editableUserFullName}
                                                placeholder="Enter Your Full Name"
                                                style={{ borderTopRightRadius: '4px', borderBottomRightRadius: '4px' }}
                                            />
                                            <Form.Control.Feedback type="invalid" style={{ fontSize: '.8rem' }}>
                                                Please provide a valid Title.
                                            </Form.Control.Feedback>
                                            {/* </Form.Group> */}
                                        </InputGroup>
                                        <div className="head-buttons d-flex gap-2">
                                            <Button className='' variant='primary' onClick={handleSaveEditedProfile}>Save</Button>
                                            <Button className='' variant='outline-primary' onClick={handleProfileCancelEdit}>Cancel</Button>
                                        </div>
                                    </div>
                                    <Card.Text className='d-flex align-items-center py-2 w-50'>
                                        {/* <InputGroup.Text id="inputGroup-sizing-default" style={{ height: '37px' }}>
                                            <ion-icon name="briefcase-outline" style={{ color: '#45a994' }}></ion-icon>
                                            <IoLocationOutline style={{color: '#45a994'}}/>
                                        </InputGroup.Text> */}

                                        <InputGroup>
                                            <InputGroup.Text>
                                                <ion-icon name="briefcase-outline"></ion-icon>
                                            </InputGroup.Text>
                                            {/* <Form.Control aria-label="Dollar amount (with dot and two decimal places)" /> */}
                                            <Form.Select
                                                className='w-50'
                                                aria-label="Default select example"
                                                onChange={(e) => setEditableUserDomain(e.target.value)}
                                                value={editableUserDomain}
                                            >
                                                {/* <option disabled value={userDomain}>{userDomain}</option> */}
                                                {jobRoles.map((role, idx) => {
                                                    return <option key={idx} value={role.ID}>{role.Title}</option>
                                                })}
                                            </Form.Select>
                                        </InputGroup>
                                    </Card.Text>
                                </>
                                :
                                <>
                                    <div className='d-flex justify-content-between flex-column flex-md-row'>
                                        <div className="user-name">
                                            <h3 className="name">
                                                <span class="d-flex align-items-center">
                                                    {userInfo?.FullName}
                                                    <div class="animation-container d-flex align-items-center justify-content-center ms-1 cursor-pointer" onClick={() => setShowGetVspModal(true)} style={{ marginTop: '8px' }}>
                                                        <div class="icon star" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                            <img src={ScopehaiLogo} width="100%" />
                                                        </div>
                                                        <div class="icon box" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: '100px', border: 'solid 2.5px #FFBC00', height: '24px', width: '24px' }}>
                                                            <img src={StarIcon} width="90%" />
                                                        </div>
                                                    </div>
                                                </span>
                                                {perticularApplication[0]?.Shortlist === true &&
                                                    <span className="text-muted ps-1">shortlisted</span>
                                                }
                                            </h3>
                                            <h5 className="subtext">{userInfo?.CareerProfile?.degree}</h5>
                                            {/*  <h5 className="subtext">{userDomain}</h5> */}
                                        </div>
                                        {/* <span className="badge bg-primary ms-1 text-white">Edit</span> */}
                                        {role === 'Candidate' &&
                                            <div class="row">
                                                <div class="col-12 col-md-12 d-flex justify-content-between mb-1">
                                                    <Button variant="outline-primary" className='me-2 btn-block' onClick={handleProfileEdit}>Edit Profile</Button>
                                                    <>
                                                        {role === "Candidate" &&
                                                            <>
                                                                {
                                                                    userInfo?.CV && <Button
                                                                        variant="outline-primary"
                                                                        href={userInfo.CV}
                                                                        download="User_CV"
                                                                        target="_blank"
                                                                        className="btn btn-block"
                                                                    >
                                                                        Download CV
                                                                    </Button>
                                                                }
                                                            </>
                                                        }
                                                        {(role === "Admin" || role === "Employer") &&
                                                            <>
                                                                {
                                                                    userInfo?.CV &&
                                                                    <Button
                                                                        variant="outline-primary"
                                                                        href={userInfo.CV}
                                                                        download="User_CV"
                                                                        target="_blank"
                                                                        className="btn btn-block"
                                                                    > Download CV </Button>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                                </div>
                                                <div class="col-12 col-md-12">
                                                    {role === 'Candidate' &&
                                                        <FileUploadWithProgress onUploadSuccess={handleCVUploadSuccess} />
                                                    }
                                                </div>
                                                {/* <Button variant='outline-primary' className='do-this-later' onClick={handleLater}>I'll do this later</Button> */}
                                            </div>
                                        }
                                    </div>

                                </>
                            }
                        </div>
                    </div>
                </div>



                {/* User Statistics Start | Display None */}
                <div className="section full mt-2 d-none">
                    <div className="row">
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="person-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">1.7k+</h5>
                                        <h6 className="card-subtitle">Total Visitors</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="bookmarks-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">03</h5>
                                        <h6 className="card-subtitle">Shortlisted</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="eye-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">2.1k</h5>
                                        <h6 className="card-subtitle">Views</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="checkmark-done-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">07</h5>
                                        <h6 className="card-subtitle">Applied Jobs</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* User Statistics End */}

                <div className="section mt-1 mb-2">
                    <div className="profile-info">
                        <div className=" bio">

                            <Form noValidate validated={validated} onSubmit="">
                                <Row>
                                    <Col md={6} xs={12}>
                                        <Form.Group className="d-flex align-items-center">
                                            <Form.Label className="me-2">Open To Join</Form.Label>
                                            <div className="form-check form-switch">
                                                <input
                                                    className="open-to-join form-check-input"
                                                    type="checkbox"
                                                    id="SwitchCheckDefault1"
                                                    checked={openToJoin}
                                                    onChange={handleOpenToJoin}
                                                />
                                                <label className="form-check-label" htmlFor="SwitchCheckDefault1"></label>
                                            </div>
                                        </Form.Group>
                                        <Form.Group className="d-flex align-items-center">
                                            <Form.Label className="me-2">Open To Switch</Form.Label>
                                            <div className="form-check form-switch">
                                                <input
                                                    className="open-to-join form-check-input"
                                                    type="checkbox"
                                                    id="SwitchCheckDefault2"
                                                    checked={openToSwitch}
                                                    onChange={handleOpenToSwitch}
                                                />
                                                <label className="form-check-label" htmlFor="SwitchCheckDefault2"></label>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} xs={12}>
                                        {openToJoin === true ?
                                            <>
                                                <InputGroup className="mb-3">
                                                    <Form.Control
                                                        required
                                                        type="text"
                                                        value={expectedCTC}
                                                        onChange={handleExpectedCTCChange}
                                                        // onBlur={handleUserStatus}
                                                        placeholder="Add Expected CTC"
                                                    // isInvalid={validated}
                                                    />

                                                    <InputGroup.Text
                                                        id="basic-addon2"
                                                        onClick={handleUserStatus}
                                                        style={{ color: "#45a994" }}
                                                    >LPA
                                                        {/* <ion-icon
                                                            style={{ fontSize: "25px", color: "#45a994" }} name="chevron-forward-outline">
                                                        </ion-icon> */}
                                                    </InputGroup.Text>
                                                </InputGroup>
                                                {/* <Button variant="primary" className='me-2 btn-block w-20 mt-1' onClick={handleUserStatus}>Save</Button> */}
                                            </> : <> </>
                                        }
                                        {
                                            openToSwitch === true ?
                                                <>
                                                    <Form.Group className="d-flex align-items-center">
                                                        <InputGroup>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                value={expectedCTC}
                                                                onChange={handleExpectedCTCChange}
                                                                onBlur={handleUserStatus}
                                                                placeholder="Add Expected CTC" />
                                                            <InputGroup.Text
                                                                id="basic-addon2"
                                                                onClick={handleUserStatus}
                                                                style={{ color: "#45a994" }}
                                                            >LPA
                                                                {/* <ion-icon
                                                                    style={{ fontSize: "25px", color: "#45a994", }} name="chevron-forward-outline">
                                                                </ion-icon> */}
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    </Form.Group>
                                                    <Form.Group className="d-flex align-items-center mt-1">
                                                        <InputGroup>
                                                            <Form.Control
                                                                required
                                                                type="text"
                                                                value={currentCTC}
                                                                onChange={handleCurrentCTCChange}
                                                                onBlur={handleUserStatus}
                                                                placeholder="Add Current CTC"

                                                            />
                                                            <InputGroup.Text
                                                                id="basic-addon2"
                                                                onClick={handleUserStatus}
                                                                style={{ color: "#45a994" }}
                                                            >LPA
                                                                {/* <ion-icon
                                                                    style={{ fontSize: "25px", color: "#45a994", }} name="chevron-forward-outline">
                                                                </ion-icon> */}
                                                            </InputGroup.Text>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </> : <></>
                                        }
                                    </Col>
                                </Row>
                            </Form>



                            {isEditingProfile ?
                                <>
                                    <Form.Group className='my-3'>
                                        <Form.Label>General Skills</Form.Label>
                                        <Select
                                            required
                                            onChange={(selectedOptions) => setEditableGenSkills(selectedOptions)}
                                            // defaultValue={defaultGeneralSkillOptions}
                                            value={editableGenSkills}
                                            isMulti
                                            name="skills"
                                            options={generalSkillOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Form.Group>
                                    <Form.Group className='my-3'>
                                        <Form.Label>Professional Skills</Form.Label>
                                        <Select
                                            required
                                            onChange={(selectedOptions) => setEditableProSkills(selectedOptions)}
                                            // defaultValue={defaultProfessionalSkillOptions}
                                            value={editableProSkills}
                                            isMulti
                                            name="skills"
                                            options={professionalSkillOptions}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                        />
                                    </Form.Group>
                                    <div className="pb-2">
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                            <Form.Label>Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                rows={5}
                                                maxLength={150}
                                                onChange={(e) => setEditableDescription(e.target.value)}
                                                value={editableDescription}
                                            />
                                        </Form.Group>
                                    </div>
                                </>
                                :
                                <Card.Text>
                                    <div className="pb-2">
                                        <h5>General Skills:</h5>
                                        <div>
                                            {userInfo?.SkillProfile?.GeneralSkills ?
                                                userInfo?.SkillProfile?.GeneralSkills.map((skill, index) => (
                                                    <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip">
                                                        <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={tick} />{skill}</span>
                                                    </div>
                                                )) : <span className="text-muted">No General Skill Found</span>
                                            }
                                        </div>
                                        <div>
                                            {/* modified the api, can't go forward as i couldn't run api at my local network (check the console log at line number 104 & 105 and change the code)*/}
                                            {/* {
                                                (role === 'Employer' || role === 'Admin') && 
                                                (
                                                    removedGeneralSkills.map((skill, index) => (
                                                        <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip position-relative strike-through">
                                                        <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={gayTick}/>{skill}</span>
                                                    </div>
                                                    ))
                                                )
                                            } */}
                                        </div>
                                    </div>
                                    <div className="pb-2">
                                        <h5>Professional Skills:</h5>
                                        <div>
                                            {userInfo?.SkillProfile?.ProfessionalSkills ?
                                                userInfo?.SkillProfile?.ProfessionalSkills.map((skill, index) => (
                                                    <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip">
                                                        <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={tick} />{skill}</span>
                                                    </div>
                                                )) : <span className="text-muted">No Professioanl Skill Found</span>
                                            }
                                        </div>
                                        <div>
                                            {/* modified the api, can't go forward as i couldn't run api at my local network (check the console log at line number 104 & 105 and change the code)*/}
                                            {/* {
                                                (role === 'Employer' || role === 'Admin') && 
                                                (
                                                    removedProfessinalSkills.map((skill, index) => (
                                                        <div key={index} className="chip bg-transparent text-black ms-05 mb-1 skill-chip position-relative strike-through">
                                                        <span className="chip-label d-flex justify-content-center align-items-center gap-1"><Image className='tick-outlined' src={gayTick}/>{skill}</span>
                                                    </div>
                                                    ))
                                                )
                                            } */}
                                        </div>
                                    </div>
                                    <div className="pb-2">
                                        <h5>Description:</h5>
                                        <div className='description'>
                                            {role === "Candidate" &&
                                                <>
                                                    {userInfo?.Description ? userInfo.Description : <p>Edit your profile to add description</p>}
                                                </>
                                            }
                                            {(role === "Admin" || role === "Employer") &&
                                                <>
                                                    {userInfo?.Description ? userInfo.Description : <p>No description found.</p>}
                                                </>
                                            }
                                        </div>
                                    </div>

                                    {!userInfo?.CV &&
                                        <div className="pb-2">
                                            <div className='description'>
                                                {role === "Candidate" &&
                                                    <>
                                                        {
                                                            !userInfo?.CV &&
                                                            <p>You have not uploaded your CV yet.</p>
                                                        }
                                                    </>
                                                }
                                                {(role === "Admin" || role === "Employer") &&
                                                    <>
                                                        {
                                                            !userInfo?.CV &&
                                                            <p>No CV found.</p>}
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    }
                                    {/* upload CV */}
                                    {/* {role === 'Candidate' &&
                                        <FileUploadWithProgress onUploadSuccess={handleCVUploadSuccess} />

                                    } */}
                                </Card.Text>
                            }
                        </div>
                    </div>
                </div>

                <div class="d-none d-sm-block">
                    <div className="section full">
                        <div className="wide-block transparent p-0">
                            <ul className="nav nav-tabs lined iconed candidateProfile" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link" href="#education" role="tab">
                                        <i className="fa fa-graduation-cap me-1"></i>Education
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#experience" role="tab">
                                        <i className="fa fa-briefcase me-1"></i>Experience
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="section full mb-2">
                        <div className="tab-content">
                            <div class="row">
                                <div class="col-md-6">
                                    <div className="tab-pane fade active show" role="tabpanel">
                                        <div className="add-education-btn position-relative pb-3 pt-2">
                                            {role === 'Candidate' && <Button className='position-absolute z-index-10' variant="primary" onClick={() => setEduModalShow(true)} style={{ left: '3.4rem' }}>
                                                <FaPlus />
                                            </Button>}
                                        </div>
                                        <div className="timeline timed">
                                            {(userEduction && userEduction.length > 0) ?
                                                userEduction && userEduction
                                                    .slice()
                                                    .sort((a, b) => b.endYear - a.endYear)
                                                    .map((education, idx) => {
                                                        let startYear = parseInt(education.startYear);
                                                        let endYear = parseInt(education.endYear);

                                                        // Check if endYear is NaN, then set it to the current year
                                                        if (isNaN(endYear)) {
                                                            endYear = new Date().getFullYear();
                                                        }

                                                        const yearsDifference = endYear - startYear;
                                                        const duration = `(${yearsDifference} Years)`;

                                                        return (
                                                            <div className="item" id={idx}>
                                                                <span className="time">{education?.startYear} - {education?.pursuing === true ? "pursuing" : education?.endYear} <br /><div className="text">{duration}</div></span>
                                                                <div className="dot"></div>
                                                                <div className="content">
                                                                    <div className='d-flex align-items-center'>
                                                                        <img src={logoPhoto} alt="avatar" className="imaged w48 rounded" />
                                                                        <h4 className="title m-0">{education?.institution}</h4>
                                                                    </div>
                                                                    <div className="text">{education?.qualification}</div>
                                                                    <div className="text">{education?.degree}</div>
                                                                    {/* <div className="text">{duration}</div> */}
                                                                    {role === 'Candidate' &&
                                                                        <div className="d-flex gap-2">
                                                                            <Button style={{ padding: "3px 10px", fontSize: "16px" }} className="PD-action-btn" onClick={() => handleShowEduUpdateModal(education)}>
                                                                                <FaPen />
                                                                                </Button>
                                                                            <Button style={{ padding: "3px 10px", fontSize: "16px" }} className="PD-action-btn" variant="danger" onClick={() => handleShowDeleteModal('education', education?.eduID)}><RiDeleteBin6Line /></Button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                : <div className="item">
                                                    <div className="content">
                                                        <div className='d-flex align-items-center'>
                                                            <img src={logoPhoto} alt="avatar" className="imaged w48 rounded" />
                                                            {role === "Candidate" &&
                                                                <h4 className="title m-0">Add your Education</h4>
                                                            }
                                                            {(role === "Admin" || role === "Employer") &&
                                                                <h4 className="title m-0">No Education Found</h4>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            <GetVspModal

                                                // onHide={() => setVspModalShow(false)}
                                                show={showGetVspModal}
                                                handleClose={handleCloseGetVspModal}
                                            />

                                            <AddNewEducation
                                                refetchUserData={refetchUserData}
                                                show={eduModalShow}
                                                onHide={() => setEduModalShow(false)}
                                            />

                                            <DeleteEducation
                                                show={modalShow}
                                                onHide={() => setModalShow(false)}
                                                titleMessage={modalTitle}
                                                confirmDeleteMessage={modalMessage}
                                                handleDelete={handleDelete}
                                            />

                                            <UpdateEducationModal
                                                show={updateEduModalShow}
                                                onHide={() => setUpdateEduModalShow(false)}
                                                education={editabelEducation}
                                                refetchUserData={refetchUserData}
                                            />


                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div className="tab-pane fade active show" role="tabpanel">
                                        <div className="add-education-btn position-relative pb-3 pt-2">
                                            {role === 'Candidate' && <Button className='position-absolute z-index-10' variant="primary" onClick={() => setExpModalShow(true)} style={{ left: '3.4rem' }}>
                                                <FaPlus />
                                            </Button>}
                                        </div>
                                        <div className="timeline timed">
                                            {(userExperience && userExperience.length > 0) ?
                                                userExperience && userExperience
                                                    .slice()
                                                    .sort((a, b) => {
                                                        if (a.working && !b.working) return -1;
                                                        if (!a.working && b.working) return 1;

                                                        // Parse the end dates for sorting
                                                        const endDateA = a.working ? new Date() : new Date(a.endYear);
                                                        const endDateB = b.working ? new Date() : new Date(b.endYear);

                                                        // Sort by end date (year and month)
                                                        if (endDateA > endDateB) return -1;
                                                        if (endDateA < endDateB) return 1;
                                                        return 0;
                                                    })
                                                    .map((experience, idx) => {
                                                        //// Parse the start date
                                                        const startDate = new Date(experience.startYear);

                                                        if (isNaN(startDate)) {
                                                            console.error(`Invalid StartYear: ${experience.startYear}`);
                                                            return null;
                                                        }
                                                        //// Get the month name and year
                                                        const startMonth = new Intl.DateTimeFormat('en', { month: 'long' }).format(startDate);
                                                        const startYear = startDate.getFullYear();

                                                        //// Parse the end date or set it to the current year if undefined
                                                        let endDate = new Date();
                                                        if (experience.endYear) {
                                                            endDate = new Date(experience.endYear);
                                                            if (isNaN(endDate)) {
                                                                console.error(`Invalid EndYear: ${experience.endYear}`);
                                                                return null;
                                                            }
                                                        }

                                                        //// Calculate the difference in years and months
                                                        const yearsDifference = endDate.getFullYear() - startDate.getFullYear();
                                                        const monthsDifference = endDate.getMonth() - startDate.getMonth();
                                                        const totalMonths = yearsDifference * 12 + monthsDifference;

                                                        //// Format the duration
                                                        const years = Math.floor(totalMonths / 12);
                                                        const months = totalMonths % 12;
                                                        const duration = `(${years} Year${years !== 1 ? 's' : ''} ${months} Month${months !== 1 ? 's' : ''})`;

                                                        return (
                                                            <div className="item" id={idx} key={idx}>
                                                                <span className="time">{startMonth} {startYear} - {experience.working ? 'Present' : experience.endYear}</span>
                                                                <div className="dot"></div>
                                                                <div className="content">
                                                                    <div className='d-flex align-items-center'>
                                                                        <img src={logoPhoto} alt="avatar" className="imaged w48 rounded" />
                                                                        <h4 className="title m-0">{experience.company}</h4>
                                                                    </div>
                                                                    <div className="text">{duration}</div>
                                                                    <div className="text">{experience.designation}</div>
                                                                    {role === 'Candidate' &&
                                                                        <div className="d-flex gap-2">
                                                                            <Button style={{ padding: "3px 10px", fontSize: "16px" }} className="PD-action-btn" onClick={() => handleShowExpUpdateModal(experience)}><FaPen /></Button>
                                                                            <Button style={{ padding: "3px 10px", fontSize: "16px" }} className="PD-action-btn" variant="danger" onClick={() => handleShowDeleteModal('experience', null, experience.expID)}><RiDeleteBin6Line /></Button>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                : <div className="item">
                                                    <div className="content">
                                                        <div className='d-flex align-items-center'>
                                                            <img src={logoPhoto} alt="avatar" className="imaged w48 rounded" />
                                                            {(role === "Candidate") &&
                                                                <h4 className="title m-0">Add your Experience</h4>
                                                            }
                                                            {(role === "Admin" || role === "Employer") &&
                                                                <h4 className="title m-0">No Experience found</h4>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            }

                                            <AddNewExperience
                                                refetchUserData={refetchUserData}
                                                show={expModalShow}
                                                onHide={() => setExpModalShow(false)}
                                            />


                                            <UpdateExperienceModal
                                                show={updateExpModalShow}
                                                onHide={() => setUpdateExpModalShow(false)}
                                                experience={editableExperience}
                                                refetchUserData={refetchUserData}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="d-block d-sm-none">
                    <div className="section full">
                        <div className="wide-block transparent p-0">
                            <ul className="nav nav-tabs lined iconed candidateProfile" role="tablist">
                                <li className="nav-item">
                                    <a className="nav-link active" data-bs-toggle="tab" href="#education" role="tab">
                                        <i className="fa fa-graduation-cap me-1"></i>Education
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" data-bs-toggle="tab" href="#experience" role="tab">
                                        <i className="fa fa-briefcase me-1"></i>Experience
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="section full mb-2">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="education" role="tabpanel">
                                <div className="add-education-btn position-relative pb-3 pt-2">
                                    {role === 'Candidate' && <Button className='position-absolute z-index-10' variant="primary" onClick={() => setEduModalShow(true)} style={{ left: '3.4rem' }}>
                                        <FaPlus />
                                    </Button>}
                                </div>
                                <div className="timeline timed">
                                    {(userEduction && userEduction.length > 0) ?
                                        userEduction && userEduction
                                            .slice()
                                            .sort((a, b) => b.endYear - a.endYear)
                                            .map((education, idx) => {
                                                let startYear = parseInt(education.startYear);
                                                let endYear = parseInt(education.endYear);

                                                // Check if endYear is NaN, then set it to the current year
                                                if (isNaN(endYear)) {
                                                    endYear = new Date().getFullYear();
                                                }

                                                const yearsDifference = endYear - startYear;
                                                const duration = `(${yearsDifference} Years)`;

                                                return (
                                                    <div className="item" id={idx}>
                                                        <span className="time">{education?.startYear} - {education?.pursuing === true ? "pursuing" : education?.endYear} <br /><div className="text">{duration}</div></span>
                                                        <div className="dot"></div>
                                                        <div className="content">
                                                            <div className='d-flex align-items-center'>
                                                                <img src={logoPhoto} alt="avatar" className="imaged w48 rounded" />
                                                                <h4 className="title m-0">{education?.institution}</h4>
                                                            </div>
                                                            <div className="text">{education?.qualification}</div>
                                                            <div className="text">{education?.degree}</div>
                                                            {/* <div className="text">{duration}</div> */}
                                                            {role === 'Candidate' &&
                                                                <div className="d-flex gap-2">
                                                                    <Button style={{ padding: "3px 10px", fontSize: "16px" }} className="PD-action-btn" onClick={() => handleShowEduUpdateModal(education)}><FaPen /></Button>
                                                                    <Button style={{ padding: "3px 10px", fontSize: "16px" }} className="PD-action-btn" variant="danger" onClick={() => handleShowDeleteModal('education', education?.eduID)}><RiDeleteBin6Line /></Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        : <div className="item">
                                            <div className="content">
                                                <div className='d-flex align-items-center'>
                                                    <img src={logoPhoto} alt="avatar" className="imaged w48 rounded" />
                                                    {role === "Candidate" &&
                                                        <h4 className="title m-0">Add your Education</h4>
                                                    }
                                                    {(role === "Admin" || role === "Employer") &&
                                                        <h4 className="title m-0">No Education Found</h4>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    <GetVspModal

                                        // onHide={() => setVspModalShow(false)}
                                        show={showGetVspModal}
                                        handleClose={handleCloseGetVspModal}
                                    />

                                    <AddNewEducation
                                        refetchUserData={refetchUserData}
                                        show={eduModalShow}
                                        onHide={() => setEduModalShow(false)}
                                    />

                                    <DeleteEducation
                                        show={modalShow}
                                        onHide={() => setModalShow(false)}
                                        titleMessage={modalTitle}
                                        confirmDeleteMessage={modalMessage}
                                        handleDelete={handleDelete}
                                    />

                                    <UpdateEducationModal
                                        show={updateEduModalShow}
                                        onHide={() => setUpdateEduModalShow(false)}
                                        education={editabelEducation}
                                        refetchUserData={refetchUserData}
                                    />


                                </div>
                            </div>

                            <div className="tab-pane fade" id="experience" role="tabpanel">
                                <div className="add-education-btn position-relative pb-3 pt-2">
                                    {role === 'Candidate' && <Button className='position-absolute z-index-10' variant="primary" onClick={() => setExpModalShow(true)} style={{ left: '3.4rem' }}>
                                        <FaPlus />
                                    </Button>}
                                </div>
                                <div className="timeline timed">
                                    {(userExperience && userExperience.length > 0) ?
                                        userExperience && userExperience
                                            .slice()
                                            .sort((a, b) => {
                                                if (a.working && !b.working) return -1;
                                                if (!a.working && b.working) return 1;

                                                // Parse the end dates for sorting
                                                const endDateA = a.working ? new Date() : new Date(a.endYear);
                                                const endDateB = b.working ? new Date() : new Date(b.endYear);

                                                // Sort by end date (year and month)
                                                if (endDateA > endDateB) return -1;
                                                if (endDateA < endDateB) return 1;
                                                return 0;
                                            })
                                            .map((experience, idx) => {
                                                //// Parse the start date
                                                const startDate = new Date(experience.startYear);

                                                if (isNaN(startDate)) {
                                                    console.error(`Invalid StartYear: ${experience.startYear}`);
                                                    return null;
                                                }
                                                //// Get the month name and year
                                                const startMonth = new Intl.DateTimeFormat('en', { month: 'long' }).format(startDate);
                                                const startYear = startDate.getFullYear();

                                                //// Parse the end date or set it to the current year if undefined
                                                let endDate = new Date();
                                                if (experience.endYear) {
                                                    endDate = new Date(experience.endYear);
                                                    if (isNaN(endDate)) {
                                                        console.error(`Invalid EndYear: ${experience.endYear}`);
                                                        return null;
                                                    }
                                                }

                                                //// Calculate the difference in years and months
                                                const yearsDifference = endDate.getFullYear() - startDate.getFullYear();
                                                const monthsDifference = endDate.getMonth() - startDate.getMonth();
                                                const totalMonths = yearsDifference * 12 + monthsDifference;

                                                //// Format the duration
                                                const years = Math.floor(totalMonths / 12);
                                                const months = totalMonths % 12;
                                                const duration = `(${years} Year${years !== 1 ? 's' : ''} ${months} Month${months !== 1 ? 's' : ''})`;

                                                return (
                                                    <div className="item" id={idx} key={idx}>
                                                        <span className="time">{startMonth} {startYear} - {experience.working ? 'Present' : experience.endYear}</span>
                                                        <div className="dot"></div>
                                                        <div className="content">
                                                            <div className='d-flex align-items-center'>
                                                                <img src={logoPhoto} alt="avatar" className="imaged w48 rounded" />
                                                                <h4 className="title m-0">{experience.company}</h4>
                                                            </div>
                                                            <div className="text">{duration}</div>
                                                            <div className="text">{experience.designation}</div>
                                                            {role === 'Candidate' &&
                                                                <div className="d-flex gap-2">
                                                                    <Button style={{ padding: "3px 10px", fontSize: "16px" }} className="PD-action-btn" onClick={() => handleShowExpUpdateModal(experience)}><FaPen /></Button>
                                                                    <Button style={{ padding: "3px 10px", fontSize: "16px" }} className="PD-action-btn" variant="danger" onClick={() => handleShowDeleteModal('experience', null, experience.expID)}><RiDeleteBin6Line /></Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                );
                                            })
                                        : <div className="item">
                                            <div className="content">
                                                <div className='d-flex align-items-center'>
                                                    <img src={logoPhoto} alt="avatar" className="imaged w48 rounded" />
                                                    {(role === "Candidate") &&
                                                        <h4 className="title m-0">Add your Experience</h4>
                                                    }
                                                    {(role === "Admin" || role === "Employer") &&
                                                        <h4 className="title m-0">No Experience found</h4>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <AddNewExperience
                                        refetchUserData={refetchUserData}
                                        show={expModalShow}
                                        onHide={() => setExpModalShow(false)}
                                    />


                                    <UpdateExperienceModal
                                        show={updateExpModalShow}
                                        onHide={() => setUpdateExpModalShow(false)}
                                        experience={editableExperience}
                                        refetchUserData={refetchUserData}
                                    />

                                </div>
                            </div>

                            {/* <div className="d-flex justify-content-center mt-5">
                                <div className="">
                                    {role === 'Candidate' &&
                                        <div className='d-flex'>
                                            <Button className='me-5' variant="primary" href='/'>
                                                Save & Exit
                                            </Button>
                                            <Button variant="warning" style={{ width: "7rem" }} onClick={() => setShowGetVspModal(true)} >
                                                Get VSP
                                            </Button>
                                        </div>

                                    }
                                </div>
                                <div className="">
                                    {(role === 'Employer') &&
                                        (perticularApplication.length > 0 ?
                                            ((perticularApplication[0]?.Shortlist === true) ?
                                                <Button variant="primary" onClick={viewNextApplicant}>
                                                    View Next Applicant
                                                </Button>
                                                :
                                                <Button variant="primary" onClick={handlleShortlistAndNext}>
                                                    Shortlist & View Next Applicant
                                                </Button>)
                                            :
                                            <Button variant="primary" onClick={handlleShortlistAndNext}>
                                                Shortlist & View Next Applicant
                                            </Button>
                                        )
                                    }
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>

            </div>
            {/* {profileProgressCount < 100 && */}
            <div class="text-black d-flex align-items-center justify-content-center" style={{ position: 'fixed', bottom: 0, right: 0, left: 0, height: '110px', background: '#cdffd8', zIndex: 999 }}>
                <div class="row w-100 ps-4 pe-4">
                    <div class="col-12 col-md-12 d-flex justify-content-between">
                        <h3 style={{ marginBottom: '4px' }}>Complete Profile</h3>
                        <h6 class="btn btn-sm btn-link mb-0 p-0 align-items-start cursor-pointer" style={{ marginBottom: '4px' }}><u style={{ paddingTop: '2px' }} onClick={handleLater}>Save & Continue</u></h6>
                    </div>
                    <div class="col-12 col-md-12" style={{ position: 'relative' }}>
                        <h6 class="d-flex">
                            Tips:&nbsp;
                            <div class="w-100" style={{ position: 'relative', overflow: 'hidden' }}>
                                <TextAnimation messages={messages} interval={4000} />
                            </div>
                        </h6>
                        <ProgressBar animated now={profileProgressCount} className="w-100 bg-white" style={{ height: '10px' }} />
                        <h6 class="text-end" style={{ position: 'absolute', top: 0, right: 8, color: '#45a994' }}>
                            100%
                        </h6>
                    </div>
                </div>
            </div>
            {/* } */}
        </div>
    )
}

const mapStateToProps = ({ auth }) => {
    const { userData, allCities, role, fullName, ID, authToken } = auth

    return {
        userData,
        allCities,
        role,
        fullName,
        ID,
        authToken
    }
}

const mapDispatchToProps = {
}

export default connect(mapStateToProps, mapDispatchToProps)(CandidateProfile)


// Delete modal component
function DeleteEducation(props) {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.titleMessage}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{props.confirmDeleteMessage}</h4>
                {/* <h4>Are you sure you want to delete this Placement Drive?</h4> */}
                {/* <p>
            This action cannot be undone. Once you confirm, the job will be permanently deleted from the system.
        </p> */}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="danger" onClick={props.handleDelete}>Confirm Delete</Button>
            </Modal.Footer>
        </Modal>
    )

}
