import { React, useState, useEffect, useRef } from 'react'
import {
  Badge,
  Button,
  Card,
  Col,
  Form,
  InputGroup,
  Row,
  Alert,
  Search
} from 'react-bootstrap'
import { connect } from 'react-redux'
import axios from 'axios'
import Select from 'react-select'
import {
  GetCandidateByID,
  fetchAllJobRoles,
  fetchAllJobRolesSkills,
  fetchAllSkills
} from '../../../features/constants/apis'
import { Splide, SplideSlide } from '@splidejs/react-splide'
import { FaCheckCircle, FaSearch } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { RxCross2 } from 'react-icons/rx'
import tick from '../../images/tick.png'
import tickOutlined from '../../images/tick-outlined.png'
import selectGenSkillModal from '../Modals/selectGenSkillModal'
import { useNavigate } from 'react-router-dom'
import { updateRegistrationStep } from '../../../features/auth/authSlice'
import { relativeTimeRounding } from 'moment'
const RegFormStep2 = ({
  authToken,
  tokenData,
  changeForm,
  submitFormStep2,
  completeProfileStep,
  updateRegistrationStep,
  ID,
  fullName,
  userData,
  role
}) => {
  const [validated, setValidated] = useState(false)
  const [selectGSkills, setSelectGSkills] = useState([])
  const [selectPSkills, setSelectPSkills] = useState([])
  const [uploadedFile, setUploadedFile] = useState(null)
  const [fileUploaded, setFileUploaded] = useState(false)
  const [selectNotification, setSelectNotification] = useState(false)
  const [candidateData, setCandidateData] = useState([])
  const [jobRoles, setJobRoles] = useState([])
  const [jobRolesSkills, setJobRolesSkills] = useState({
    GeneralSkills: [],
    ProfessionalSkills: [],
    ProSuggestedSkills: [],
    GenSuggestedSkills: []
  })
  const [matchingJobRoleData, setMatchingJobRolesData] = useState([])
  const [checkboxGenStates, setCheckboxGenStates] = useState([])
  const [checkboxProStates, setCheckboxProStates] = useState([])
  const [showSelectGenSkillModal, setShowSelectGenSkillModal] = useState(false)
  const [selectSkill, setSelectSkill] = useState()
  const [inputValue, setInputValue] = useState('')
  const [options, setOptions] = useState([])
  const [proSugestSkills, setProSuggestSkills] = useState()
  const [genSugestSkills, setGenSuggestSkills] = useState()
  const [showPSAlert, setShowPSAlert] = useState(false)
  const [showGSAlert, setShowGSAlert] = useState(false)
  const [showProfSkillsOptions, setShowProfSkillsOptions] = useState(false)
  const [searchPQuery, setSearchPQuery] = useState('')
  const [searchGQuery, setSearchGQuery] = useState('')
  const [showGenSkillsOptions, setShowGenSkillsOptions] = useState(false)
  const [domainProfSkills, setDomainProfSkills] = useState()
  const [domainGenSkills, setDomainGenSkills] = useState()
  const [selectedSubdomains, setSelectedSubdomains] = useState([])

  useEffect(() => {
    // Prompt confirmation when reload page is triggered
    window.onbeforeunload = () => {
      return 'Data will be lost'
    }

    // Unmount the window.onbeforeunload event
    return () => {
      window.onbeforeunload = null
    }
  }, [])

  document.onkeydown = function (e) {
    if (e.key === 'r' && (e.ctrlKey || e.metaKey || e.shiftKey)) {
      e.preventDefault()
      return false
    }
  }

  const [getSkills, setGetSkills] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchAllSkills(authToken)
      setGetSkills(data)
    }
    fetchData()
  }, [authToken])

  const [showLoader, setShowLoader] = useState(true);
  // jobroles skills
  useEffect(() => {
    const fetchData = async () => {
      setShowLoader(true);
      try {
        const data = await fetchAllJobRolesSkills(authToken)
        setJobRolesSkills(data.data)
      } catch (error) {
        console.error("Error fetching skills.", error);
      } finally {
        setShowLoader(false);
      }
    };
    fetchData()
  }, [authToken])

  useEffect(() => {
    const domainValue = candidateData?.CareerProfile?.domain
    const matchingJobRole = jobRoles.find(jobRole => jobRole?.ID == domainValue)
    // setProSuggestSkills(matchingJobRole)
    setGenSuggestSkills(matchingJobRole)

    if (matchingJobRole) {
      const matchingDomain = matchingJobRole?.Domain
      const jobRoleWithMatchingDomain = jobRoles.filter(
        jobRole => jobRole?.Domain === matchingDomain
      )
      setMatchingJobRolesData(jobRoleWithMatchingDomain)
      setCheckboxGenStates(
        new Array(jobRoleWithMatchingDomain.length).fill(false)
      )
      setCheckboxProStates(
        new Array(jobRoleWithMatchingDomain.length).fill(false)
      )
      console.log('Matching jobRoles:', { jobRoleWithMatchingDomain, jobRoles })
      // const ProfSkillsbyDomain = [
      //     ...new Set(
      //         jobRoleWithMatchingDomain
      //             .map(jobRole => jobRole?.ProfessionalSkills || [])
      //             .flat()
      //     )
      // ].map(skill => ({value: skill, label: skill}));
      const ProfSkillsbyDomain = jobRoleWithMatchingDomain
        ?.map(item => {
          return item.ProfessionalSkills?.map(skill => ({
            value: skill,
            label: skill,
            subdomain: item.Subdomain
          }))
        })
        .flat()
        .filter(itm => itm)
      console.log("Prof Skills with subdomains:", ProfSkillsbyDomain);
      const uniqueProfSkillsByDomain = [
        ...new Map(
          ProfSkillsbyDomain.map(skill => [skill.value, skill]) // Use skill.value as the unique key
        ).values()
      ]
      console.log("Unique Prof Skills by Domain:", uniqueProfSkillsByDomain);
      setDomainProfSkills(uniqueProfSkillsByDomain);

      const TopProfSkillsbyDomain = jobRoleWithMatchingDomain
        ?.map(item => {
          return item.SuggestProSkills?.map(skill => ({
            value: skill,
            label: skill,
            subdomain: item.Subdomain
          }))
        })
        .flat()
        .filter(itm => itm)
      console.log("Top Suggested Prof Skills with subdomains:", TopProfSkillsbyDomain);
      const uniqueTopProfSkillsByDomain = [
        ...new Map(
          TopProfSkillsbyDomain.map(skill => [skill.value, skill]) // Use skill.value as the unique key
        ).values()
      ]
      console.log("Unique Top Prof Skills by Domain:", uniqueTopProfSkillsByDomain);
      setProSuggestSkills(uniqueTopProfSkillsByDomain);

      const GenSkillsbyDomain = [
        ...new Set(
          jobRoleWithMatchingDomain
            .map(jobRole => jobRole?.GeneralSkills || [])
            .flat()
        )
      ].map(skill => ({ value: skill, label: skill }))
      setDomainGenSkills(GenSkillsbyDomain)
    }
  }, [jobRoles, candidateData])

  const generalSkillOptions = jobRolesSkills?.GeneralSkills.map(skill => ({
    value: skill,
    label: skill
  }))

  const professionalSkillOptions = jobRolesSkills?.ProfessionalSkills.map(
    skill => ({
      value: skill,
      label: skill
    })
  )
  // const professionalSkillOptions = domainProfSkills?.map(skill => ({
  //     value: skill,
  //     label: skill,
  // }));

  // const proSuggestedSkillOptions = proSugestSkills?.SuggestProSkills?.map(
  //   skill => ({
  //     value: skill,
  //     label: skill
  //   })
  // )

  const genSuggestedSkillOptions = genSugestSkills?.SuggestGenSkills?.map(
    skill => ({
      value: skill,
      label: skill
    })
  )

  const handleSelectedGeneralSkills = selectedOptions => {
    const selectGSkills = selectedOptions.map(option => option.value)
    setSelectGSkills(selectGSkills)
  }
  const handleSelectedProfessionalSkills = selectedOptions => {
    const selectPSkills = selectedOptions.map(option => option.value)
    setSelectPSkills(selectPSkills)
  }

  const [hasError, setHasError] = useState({
    selectGSkills: false,
    selectPSkills: false
  })

  const showErrorMsg = e => {
    const showError = { ...hasError }
    console.log(showError)
    if (e === 'Select General Skills') {
      console.log(e)
    }
    if (e === 'Select Professional Skills') {
      console.log(e)
    }
  }

  useEffect(() => {
    const fetchCandidateByID = async () => {
      const data = await GetCandidateByID(ID, authToken)
      setCandidateData(data.data)
    }
    fetchCandidateByID()
  }, [ID, authToken])

  useEffect(() => {
    const fetchJobRoles = async () => {
      const data = await fetchAllJobRoles(authToken)
      setJobRoles(data.data)
    }
    fetchJobRoles()
  }, [authToken])

  // General Skills

  // const [checkboxGenStates, setCheckboxGenStates] = useState(Array(matchingJobRoleData.length).fill(false));
  const handleGenCheckboxChange = idx => {
    // Update the checked state for the checkbox at the given index
    setCheckboxGenStates(prevStates => {
      const newState = [...prevStates]
      newState[idx] = !newState[idx]
      return newState
    })

    if (!checkboxGenStates[idx]) {
      const seletedJobRoleGenSkills = matchingJobRoleData[idx]?.GeneralSkills
      // Add new skills to selectGSkills
      const updatedSkills = Array.from(
        new Set([...selectGSkills, ...seletedJobRoleGenSkills])
      )
      setSelectGSkills(updatedSkills)
    } else {
      const deselectedJobRoleGenSkills = matchingJobRoleData[idx]?.GeneralSkills
      // Remove deselected skills from selectGSkills
      const updatedSkills = selectGSkills.filter(
        skill => !deselectedJobRoleGenSkills.includes(skill)
      )
      setSelectGSkills(updatedSkills)
    }
  }

  const handleGenSkillSelect = skill => {
    const updatedSkills = Array.from(new Set([...selectGSkills, skill]))
    setSelectGSkills(updatedSkills)
  }

  // professional Skills

  // const [checkboxProStates, setCheckboxProStates] = useState(Array(matchingJobRoleData.length).fill(false));
  const handleProCheckboxChange = idx => {
    // Set the checked state of the checkbox
    setCheckboxProStates(prevStates => {
      const newStates = [...prevStates]
      newStates[idx] = !newStates[idx]
      return newStates
    })

    if (!checkboxProStates[idx]) {
      const selectedJobRoleProSkills =
        matchingJobRoleData[idx]?.ProfessionalSkills
      const updatedSkills = Array.from(
        new Set([...selectPSkills, ...selectedJobRoleProSkills])
      )
      setSelectPSkills(updatedSkills)
    } else {
      const deselectedJobRoleProSkills =
        matchingJobRoleData[idx]?.ProfessionalSkills
      const updatedSkills = selectPSkills.filter(
        skill => !deselectedJobRoleProSkills.includes(skill)
      )
      setSelectPSkills(updatedSkills)
    }
  }

  const handleProSkillSelect = skill => {
    const { subdomain } =
      domainProfSkills?.find(ps => ps.value === skill) ||
      proSugestSkills?.find(ps => ps.value === skill) ||
      {};
    const updatedSkills = Array.from(new Set([...selectPSkills, skill]))
    setSelectPSkills(updatedSkills)
    setSelectedSubdomains(previousSubdomains => {
      const updatedSubdomains = Array.from(
        new Set([...previousSubdomains, subdomain])
      )
      console.log('Selected Subdomains:', updatedSubdomains)
      return updatedSubdomains
    })
    console.log('Before update:', selectPSkills)
    console.log('Adding skill:', skill)
    console.log('Adding skill subdomains:', selectedSubdomains)
    console.log('After update:', updatedSkills)
  }

  const verifyGenCheckboxStates = () => {
    const updatedCheckboxGenStates = checkboxGenStates.map((checked, idx) => {
      const jobRoleSkills = matchingJobRoleData[idx]?.GeneralSkills || []
      const allSkillsPresent = jobRoleSkills.every(skill =>
        selectGSkills.includes(skill)
      )
      return allSkillsPresent
    })
    setCheckboxGenStates(updatedCheckboxGenStates)
  }

  const verifyProCheckboxStates = () => {
    const updatedCheckboxProStates = checkboxProStates.map((checked, idx) => {
      const jobRoleSkills = matchingJobRoleData[idx]?.ProfessionalSkills || []
      const allSkillsPresent = jobRoleSkills.every(skill =>
        selectPSkills.includes(skill)
      )
      return allSkillsPresent
    })
    setCheckboxProStates(updatedCheckboxProStates)
  }

  useEffect(() => {
    verifyGenCheckboxStates()
  }, [selectGSkills, matchingJobRoleData])

  useEffect(() => {
    verifyProCheckboxStates()
  }, [selectPSkills, matchingJobRoleData])

  const handleRemoveGenSkill = skill => {
    const updatedSkills = selectGSkills.filter(allSkills => allSkills !== skill)
    setSelectGSkills(updatedSkills)
  }

  const handleRemoveProSkill = skill => {
    const updatedSkills = selectPSkills.filter(allSkills => allSkills !== skill)
    setSelectPSkills(updatedSkills)
    const { subdomain } =
      domainProfSkills?.find(ps => ps.value === skill) ||
      proSugestSkills?.find(ps => ps.value === skill) ||
      {};
    setSelectedSubdomains(previousSubdomains => {
      const remainingSkillsInSubdomain = domainProfSkills
        ?.filter((ps) => ps.subdomain === subdomain && updatedSkills.includes(ps.value));
      if (!remainingSkillsInSubdomain.length) {
        return previousSubdomains.filter((sd) => sd !== subdomain);
      }
      return previousSubdomains;
    })
    console.log("Remaining subdomains:", selectedSubdomains);
  }

  const uploadRefImage = filePath => {
    setFileUploaded(true)
    const formData = new FormData()
    formData.append('inputFile', filePath)
    axios
      .post('https://api.allpos.in/applications/upload/scopehai', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => {
        setUploadedFile(res.data.data)
        setFileUploaded(false)
      })
      .catch(err => {
        console.error('Error uploading file:', err)
      })
  }

  //to privent user to go back while regitration is in progress
  const navigate = useNavigate()

  useEffect(() => {
    const handlePopState = event => {
      // Prevent the default back action
      event.preventDefault()
      // Redirect the user to the same page or another page
      navigate('/complete-profile?step=step2') // Adjust the path accordingly
    }

    window.history.pushState(null, null, window.location.pathname) // Prevent back initially
    window.addEventListener('popstate', handlePopState)

    return () => {
      window.removeEventListener('popstate', handlePopState)
    }
  }, [navigate])

  function handleGoBack() {
    const updateStep = {
      stepNo: 1,
      completeProfileStep: completeProfileStep
    }
    updateRegistrationStep(updateStep)
  }

  // filtered out duplicate domains
  const uniqueDomain = Array.from(
    new Set(
      jobRoles.filter(role => role.AppVisibility).map(role => role.Domain)
    )
  ).map(domain =>
    jobRoles.find(role => role.Domain === domain && role.AppVisibility)
  )

  const UniqueDomain = [
    { ID: 1, skill: 'java' },
    { ID: 2, skill: 'paython' },
    { ID: 3, skill: 'react' },
    { ID: 4, skill: 'node' },
    { ID: 5, skill: 'postgressql' },
    { ID: 6, skill: 'javaScript' }
  ]

  // Debounce utility function
  const debounce = (func, delay) => {
    let timeout
    return (...args) => {
      clearTimeout(timeout)
      timeout = setTimeout(() => func(...args), delay)
    }
  }

  // Debounced function to update options based on inputValue
  const updateOptions = debounce(value => {
    const filteredOptions = UniqueDomain.filter(role =>
      role.skill.toLowerCase().includes(value.toLowerCase())
    ).map(role => ({ value: role.ID, label: role.skill }))
    setOptions(filteredOptions)
  }, 1000)

  const searchProfSkillsRef = useRef()
  const handleProfSkillsFocus = () => setShowProfSkillsOptions(true)
  const handleProfSkillsBlur = e => {
    setTimeout(() => {
      if (!searchProfSkillsRef.current.contains(e.relatedTarget)) {
        setShowProfSkillsOptions(false)
      }
    }, 1000)
  }
  const handleProfSearchChange = e => {
    setSearchPQuery(e.target.value)
  }
  const filteredProfSkillOptions = domainProfSkills
    ?.filter(
      skill =>
        skill.label &&
        skill.value &&
        skill.label.trim() !== '' &&
        skill.value.trim() !== '' &&
        (searchPQuery
          ? skill.value.toLowerCase().includes(searchPQuery.toLowerCase())
          : true)
    )
    .sort((a, b) => {
      const indexA = selectedSubdomains.indexOf(a.subdomain)
      const indexB = selectedSubdomains.indexOf(b.subdomain)

      // If both skills' subdomains are in selectSubdomains, sort by their order in selectSubdomains
      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB
      }
      // If only one skill's subdomain is in selectSubdomains, prioritize it
      if (indexA !== -1) return -1
      if (indexB !== -1) return 1
      // If neither skill's subdomain is in selectSubdomains, maintain original order
      return 0
    })
  // console.log('Filtered Prof Skills:', filteredProfSkillOptions)
  const filteredTopProfSkillOptions = proSugestSkills
    ?.filter(
      skill =>
        skill.label &&
        skill.value &&
        skill.label.trim() !== '' &&
        skill.value.trim() !== '' &&
        (searchPQuery
          ? skill.value.toLowerCase().includes(searchPQuery.toLowerCase())
          : true)
    )

  const excludedProfSkills = selectPSkills
  const visibleAllProfSkillsOptions = filteredProfSkillOptions?.filter(
    skill => !excludedProfSkills.includes(skill.value)
  )
  const visibleSuggestedProfSkillsOptions = proSugestSkills?.filter(
    skill => !excludedProfSkills.includes(skill.value)
  )
  const searchGenSkillsRef = useRef()
  const handleGenSkillsFocus = () => setShowGenSkillsOptions(true)
  const handleGenSkillsBlur = e => {
    setTimeout(() => {
      if (!searchGenSkillsRef.current.contains(e.relatedTarget)) {
        setShowGenSkillsOptions(false)
      }
    }, 2000)
  }
  const handleGenSearchChange = e => {
    setSearchGQuery(e.target.value)
  }
  const filteredGenSkillOptions = domainGenSkills?.filter(
    skill =>
      skill.label &&
      skill.value &&
      skill.label.trim() !== '' &&
      skill.value.trim() !== '' &&
      (searchGQuery
        ? skill.label.toLowerCase().includes(searchGQuery.toLowerCase())
        : true)
  )
  const excludedGenSkills = selectGSkills
  const visibleAllGenSkillsOptions = filteredGenSkillOptions?.filter(
    skill => !excludedGenSkills.includes(skill.value)
  )
  const visibleSuggestedGenSkillsOptions = genSuggestedSkillOptions?.filter(
    skill => !excludedGenSkills.includes(skill.value)
  )

  // Call debounced function on inputValue change
  useEffect(() => {
    updateOptions(inputValue)
  }, [inputValue])

  function submitForm(e) {
    e.preventDefault()
    const form = e.currentTarget
    if (form.checkValidity() === false) {
      e.stopPropagation()
    }
    setShowPSAlert(false)
    setShowGSAlert(false)
    // if (selectPSkills.length === 0) {
    //     setShowPSAlert(true);
    //     showErrorMsg("Select Professional Skills");
    //     return;
    // }
    // if (selectGSkills.length === 0) {
    //     setShowGSAlert(true);
    //     showErrorMsg("Select General Skills");
    //     return;
    // }
    const newStep2RegData = {
      selectGSkills: selectGSkills,
      selectPSkills: selectPSkills,
      uploadedFile: uploadedFile,
      stepNo: 2,
      completeProfileStep: completeProfileStep,
      selectNotification: selectNotification
    }
    submitFormStep2(newStep2RegData)
    setValidated(true)
  }

  return (
    <div className="section mb-2 mt-5 full">
      <div className="wide-block pt-5 pb-2" style={{ borderBottom: 'none' }}>
        {showLoader ? (
          <div className='d-flex justify-content-center my-5 py-5'>
            <div className='loader'></div>
          </div>
        ) : (
          <Form noValidate validated={validated} onSubmit={submitForm}>
            <Form.Group className="boxed mb-4">
              <Row className="d-none">
                <Col md={5}>
                  <Form.Label htmlFor="generalSkillsLevel2">
                    Select Professional Skills
                  </Form.Label>
                </Col>
                <Col md={7}>
                  <Form.Group className="boxed mb-2">
                    <InputGroup className="boxed mb-2 position-relative">
                      {/* <InputGroup.Text id="basic-addon1"><i className="fa fa-certificate"></i></InputGroup.Text> */}
                      <Select
                        id="profSkillsLevel2"
                        isMulti
                        required
                        options={professionalSkillOptions}
                        value={selectPSkills.map(skill => ({
                          value: skill,
                          label: skill
                        }))}
                        onChange={handleSelectedProfessionalSkills}
                        placeholder="Search Professional Skills"
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={5}>
                  <Form.Label htmlFor="generalSkillsLevel2">
                    Select Professional Skills
                  </Form.Label>
                </Col>
                <Col md={7}>
                  <Form.Group className="boxed mb-2" ref={searchProfSkillsRef}>
                    <InputGroup className="boxed mb-2 position-relative">
                      <input
                        type="text"
                        id="profSkillsSearch"
                        placeholder="Search Professional Skills"
                        className="form-control"
                        value={searchPQuery}
                        onChange={handleProfSearchChange}
                        onFocus={handleProfSkillsFocus}
                        onBlur={handleProfSkillsBlur}
                      />
                      <InputGroup.Text>
                        <span className="fa fa-search"></span>
                      </InputGroup.Text>
                    </InputGroup>
                    {showProfSkillsOptions && (
                      <div className="skill-grid">
                        {visibleAllProfSkillsOptions.map((skill, skillIdx) => (
                          <div
                            key={skillIdx}
                            className="chip chip-outline chip-media chip-success ms-05 mb-05"
                            style={{ cursor: 'pointer' }}
                          >
                            <span
                              className="d-flex justify-content-center align-items-start"
                              onClick={() => handleProSkillSelect(skill.value)}
                            >
                              {selectPSkills.includes(skill.value) ? (
                                <span className="pe-1">
                                  <img src={tick} alt="avatar" />
                                </span>
                              ) : (
                                <span className="pe-1">
                                  <img src={tickOutlined} alt="avatar" />
                                </span>
                              )}
                              <span className="chip-label">{skill.label}</span>
                            </span>
                            {selectPSkills.includes(skill.value) ? (
                              <span
                                className="pe-1"
                                onClick={() => handleRemoveProSkill(skill.value)}
                              >
                                <RxCross2 />
                              </span>
                            ) : (
                              <span className="pe-3"></span>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              {/* {showPSAlert === true && (
                              <Alert variant="danger" onClose={() => setShowPSAlert(false)} dismissible>
                                  <Alert.Heading>Professional skills not selected!</Alert.Heading>
                                  <p>Please select professional skills to move forward.</p>
                              </Alert>
                          )} */}

              {/* keep this */}
              {!selectPSkills ? (
                <></>
              ) : (
                <>
                  <div
                    className="skills-container my-3"
                    style={{ minHeight: '30px' }}
                  >
                    <div className="skills-container-inner">
                      {selectPSkills.map((skill, idx) => (
                        <div
                          key={`selectedProfessionalSkills-${idx}`}
                          className="chip chip-primary ms-05 mb-05 px-2"
                        >
                          <span className="chip-label">
                            {skill}{' '}
                            <span
                              onClick={() => handleRemoveProSkill(skill)}
                              style={{ cursor: 'pointer' }}
                            >
                              <RxCross2 />
                            </span>
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </>
              )}
              {/* keep this */}
              {/* start display suggested skills */}
              <div className="mb-5">
                {visibleSuggestedProfSkillsOptions?.map((skill, skillIdx) => (
                  <div
                    key={skillIdx}
                    className="chip chip-outline chip-media chip-success ms-05 mt-05 mb-05"
                    style={{ cursor: 'pointer' }}
                  >
                    <span
                      className="d-flex justify-content-center align-items-start"
                      onClick={() => handleProSkillSelect(skill.value)}
                      style={{ cursor: 'pointer' }}
                    >
                      {selectPSkills.includes(skill.value) ? (
                        <span className="pe-1">
                          <img src={tick} alt="avatar" />
                        </span>
                      ) : (
                        <span className="pe-1">
                          <img src={tickOutlined} alt="avatar" />
                        </span>
                      )}
                      <span className="chip-label">{skill.label}</span>
                    </span>
                    {selectPSkills.includes(skill.value) ? (
                      <span
                        className="pe-1"
                        onClick={() => handleRemoveProSkill(skill.value)}
                      >
                        <RxCross2 />
                      </span>
                    ) : (
                      <span className="pe-3"></span>
                    )}
                  </div>
                ))}
              </div>
              {/* end display suggested skills */}

              {/* {matchingJobRoleData.map((jobRole, jobIdx) => (
                              jobRole?.SuggestProSkills?map((skill, skillIdx) => (
                                  <div key={`${jobIdx}-${skillIdx}`} className="chip chip-outline chip-media chip-success ms-05 mb-05" style= {{ cursor: 'pointer' }}>
                                      <span className='d-flex justify-content-center align-items-start' onClick={() => handleProSkillSelect(skill)} style={{ cursor: 'pointer' }}>
                                          {selectPSkills.includes(skill) ? (
                                              <span className='pe-1'><img src={tick} alt="avatar" /></span>
                                          ) : (
                                              <span className='pe-1'><img src={tickOutlined} alt="avatar" /></span>
                                          )}
                                          <span className="chip-label">{skill}</span>
                                      </span>
                                      {selectPSkills.includes(skill) ? (
                                          <span className='pe-1' onClick={() => handleRemoveProSkill(skill)}>
                                              <RxCross2 />
                                          </span>
                                      ) : (
                                          <span className='pe-3'></span>
                                      )}
                                  </div>
                              ))
                          ))} */}

              <Splide
                className="d-none"
                options={{
                  rewind: false,
                  gap: '1rem',
                  perMove: 1,
                  perPage: 3,
                  type: 'loop',
                  autoplay: false,
                  interval: 3000,
                  pagination: false,
                  breakpoints: {
                    875: {
                      perPage: 2
                    },
                    585: {
                      perPage: 1
                    }
                  }
                }}
                aria-label="React Splide Example"
              >
                {matchingJobRoleData.map(
                  (jobRole, idx) =>
                    jobRole?.ProfessionalSkills &&
                    jobRole?.ProfessionalSkills.length > 0 && (
                      <SplideSlide key={`jobRolePro-${idx}`}>
                        <Card
                          key={`proCard-${idx}`}
                          className="cert-card"
                          style={{ width: '18rem', height: '100%' }}
                        >
                          <Card.Body className="d-flex flex-column justify-content-between">
                            <div className="d-flex justify-content-between">
                              <Card.Title className="text-center">
                                {jobRole?.Title}
                              </Card.Title>
                              {/* <Form.Check
                                                      className='boxed mb-2'
                                                      type="checkbox"
                                                      id={`proCheckbox-${idx}`}
                                                      checked={checkboxProStates[idx]}
                                                      onChange={() => handleProCheckboxChange(idx)}
                                                  /> */}
                            </div>
                            <div>
                              {jobRole?.ProfessionalSkills.map((skill, idx) => (
                                <div
                                  key={`proSkill-${idx}`}
                                  className="chip chip-outline chip-media chip-success ms-05 mb-05"
                                  style={{ cursor: 'pointer' }}
                                >
                                  <span
                                    className="d-flex justify-content-center align-items-start"
                                    onClick={() => handleProSkillSelect(skill)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    {selectPSkills.includes(skill) ? (
                                      <span className="pe-1">
                                        <img src={tick} alt="avatar" />
                                      </span>
                                    ) : (
                                      <span className="pe-1">
                                        <img src={tickOutlined} alt="avatar" />
                                      </span>
                                    )}
                                    <span className="chip-label">{skill}</span>
                                  </span>
                                  {selectPSkills.includes(skill) ? (
                                    <span
                                      className="pe-1"
                                      onClick={() => handleRemoveProSkill(skill)}
                                    >
                                      <RxCross2 />
                                    </span>
                                  ) : (
                                    <span className="pe-3"></span>
                                  )}
                                </div>
                              ))}
                            </div>
                            <div className="d-flex align-items-end justify-content-end">
                              {!checkboxProStates[idx] ? (
                                <div className="chip chip-outline chip-primary ms-05 mb-05">
                                  <span
                                    className="d-flex justify-content-center align-items-center px-2"
                                    onClick={() => handleProCheckboxChange(idx)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <span className="chip-label">Select All</span>
                                  </span>
                                </div>
                              ) : (
                                <div className="chip chip-outline chip-danger ms-05 mb-05">
                                  <span
                                    className="d-flex justify-content-center align-items-center px-2"
                                    onClick={() => handleProCheckboxChange(idx)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <span className="chip-label">Remove All</span>
                                  </span>
                                </div>
                              )}
                            </div>
                          </Card.Body>
                        </Card>
                      </SplideSlide>
                    )
                )}
              </Splide>
            </Form.Group>

            <Form.Group className="boxed mb-4">
              <Row className="d-none">
                <Col md={5}>
                  <Form.Label htmlFor="generalSkillsLevel2">
                    Select General Skills
                  </Form.Label>
                </Col>
                <Col md={7}>
                  <Form.Group className="boxed mb-2">
                    <InputGroup className="boxed mb-2 position-relative">
                      <Select
                        options={genSuggestedSkillOptions}
                        onInputChange={value => setInputValue(value)}
                        // onChange={(selectedOption) => setSelectSkill(selectedOption?.value || null)}
                        inputValue={inputValue}
                        placeholder="Search General Skills"
                        isSearchable
                        id="profSkillsLevel2"
                        isMulti
                        required
                        value={selectGSkills.map(skill => ({
                          value: skill,
                          label: skill
                        }))}
                        onChange={handleSelectedGeneralSkills}
                      />
                    </InputGroup>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col md={5}>
                  <Form.Label htmlFor="generalSkillsLevel2">
                    Select General Skills
                  </Form.Label>
                </Col>
                <Col md={7}>
                  <Form.Group className="boxed mb-2" ref={searchGenSkillsRef}>
                    <InputGroup className="boxed mb-2 position-relative">
                      <input
                        type="text"
                        id="genSkillsSearch"
                        placeholder="Search General Skills"
                        className="form-control"
                        value={searchGQuery}
                        onChange={handleGenSearchChange}
                        onFocus={handleGenSkillsFocus}
                        onBlur={handleGenSkillsBlur}
                      />
                      <InputGroup.Text>
                        <span className="fa fa-search"></span>
                      </InputGroup.Text>
                    </InputGroup>
                    {showGenSkillsOptions && (
                      <div className="skill-grid">
                        {visibleAllGenSkillsOptions.map((skill, skillIdx) => (
                          <div
                            key={skillIdx}
                            className="chip chip-outline chip-media chip-success ms-05 mb-05"
                            style={{ cursor: 'pointer' }}
                          >
                            <span
                              className="d-flex justify-content-center align-items-start"
                              onClick={() => handleGenSkillSelect(skill.value)}
                            >
                              {selectGSkills.includes(skill.value) ? (
                                <span className="pe-1">
                                  <img src={tick} alt="avatar" />
                                </span>
                              ) : (
                                <span className="pe-1">
                                  <img src={tickOutlined} alt="avatar" />
                                </span>
                              )}
                              <span className="chip-label">{skill.label}</span>
                            </span>
                            {selectGSkills.includes(skill.value) ? (
                              <span
                                className="pe-1"
                                onClick={() => handleRemoveGenSkill(skill.value)}
                              >
                                <RxCross2 />
                              </span>
                            ) : (
                              <span className="pe-3"></span>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              {/* {showGSAlert === true && (
                              <Alert variant="danger" onClose={() => setShowGSAlert(false)} dismissible>
                                  <Alert.Heading>General skills not selected!</Alert.Heading>
                                  <p>Please select general skills to move forward.</p>
                              </Alert>
                          )} */}

              <div
                className="skills-container my-3"
                style={{ minHeight: '30px' }}
              >
                <div className="skills-container-inner d-flex justify-content-between">
                  <div>
                    {selectGSkills.map((skill, idx) => (
                      <div
                        key={`selectedGeneralSkills-${idx}`}
                        className="chip chip-primary ms-05 mb-05 px-2"
                      >
                        <span className="chip-label">
                          {skill}{' '}
                          <span
                            onClick={() => handleRemoveGenSkill(skill)}
                            style={{ cursor: 'pointer' }}
                          >
                            <RxCross2 />
                          </span>
                        </span>
                      </div>
                    ))}
                  </div>
                  {/* <Button onClick={() => { setShowSelectGenSkillModal(true); console.log("modal selected") }}><FaSearch /></Button> */}
                </div>
              </div>
              {/* start display suggested skills */}
              <div className="mb-3">
                {visibleSuggestedGenSkillsOptions?.map((skill, skillIdx) => (
                  <div
                    key={skillIdx}
                    className="chip chip-outline chip-media chip-success ms-05 mb-05"
                    style={{ cursor: 'pointer' }}
                  >
                    <span
                      className="d-flex justify-content-center align-items-start"
                      onClick={() => handleGenSkillSelect(skill.value)}
                      style={{ cursor: 'pointer' }}
                    >
                      {selectGSkills.includes(skill.value) ? (
                        <span className="pe-1">
                          <img src={tick} alt="avatar" />
                        </span>
                      ) : (
                        <span className="pe-1">
                          <img src={tickOutlined} alt="avatar" />
                        </span>
                      )}
                      <span className="chip-label">{skill.label}</span>
                    </span>
                    {selectGSkills.includes(skill.value) ? (
                      <span
                        className="pe-1"
                        onClick={() => handleRemoveGenSkill(skill.value)}
                      >
                        <RxCross2 />
                      </span>
                    ) : (
                      <span className="pe-3"></span>
                    )}
                  </div>
                ))}
              </div>
              {/* end display suggested skills */}

              <selectGenSkillModal
                show={showSelectGenSkillModal}
                onHide={setShowSelectGenSkillModal}
              />

              <Splide
                className="d-none"
                options={{
                  rewind: false,
                  gap: '1rem',
                  perMove: 1,
                  perPage: 3,
                  type: 'loop',
                  autoplay: false,
                  interval: 3000,
                  pagination: false, //this is the code for the pagination dots hide
                  breakpoints: {
                    875: {
                      perPage: 2
                    },
                    585: {
                      perPage: 1
                    }
                  }
                }}
                aria-label="React Splide Example"
              >
                {matchingJobRoleData.map(
                  (jobRole, idx) =>
                    jobRole?.GeneralSkills &&
                    jobRole?.GeneralSkills.length > 0 && (
                      <SplideSlide key={`jobRoleGen-${idx}`}>
                        {' '}
                        {/* Ensure each slide has a unique key */}
                        <Card
                          key={`genCard-${idx}`}
                          className="cert-card"
                          style={{ width: '18rem', height: '100%' }}
                        >
                          <Card.Body className="d-flex flex-column justify-content-between">
                            <div className="d-flex justify-content-between">
                              <Card.Title className="text-center">
                                {jobRole?.Title}
                              </Card.Title>
                            </div>
                            <div className="">
                              {jobRole?.GeneralSkills.map((skill, skillIdx) => (
                                <div
                                  key={`genSkill-${skillIdx}`}
                                  className="chip chip-outline chip-media chip-success ms-05 mb-05"
                                  style={{ cursor: 'pointer' }}
                                >
                                  <span
                                    className="d-flex justify-content-center align-items-start"
                                    onClick={() => handleGenSkillSelect(skill)}
                                  >
                                    {selectGSkills.includes(skill) ? (
                                      <span className="pe-1">
                                        <img src={tick} alt="avatar" />
                                      </span>
                                    ) : (
                                      <span className="pe-1">
                                        <img src={tickOutlined} alt="avatar" />
                                      </span>
                                    )}
                                    <span className="chip-label">{skill}</span>
                                  </span>
                                  {selectGSkills.includes(skill) ? (
                                    <span
                                      className="pe-1"
                                      onClick={() => handleRemoveGenSkill(skill)}
                                    >
                                      <RxCross2 />
                                    </span>
                                  ) : (
                                    <span className="pe-3"></span>
                                  )}
                                </div>
                              ))}
                            </div>
                            <div className="d-flex align-items-end justify-content-end">
                              {!checkboxGenStates[idx] ? (
                                <div className="chip chip-outline chip-primary ms-05 mb-05">
                                  <span
                                    className="d-flex justify-content-center align-items-center px-2"
                                    onClick={() => handleGenCheckboxChange(idx)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <span className="chip-label">Select All</span>
                                  </span>
                                </div>
                              ) : (
                                <div className="chip chip-outline chip-danger ms-05 mb-05">
                                  <span
                                    className="d-flex justify-content-center align-items-center px-2"
                                    onClick={() => handleGenCheckboxChange(idx)}
                                    style={{ cursor: 'pointer' }}
                                  >
                                    <span className="chip-label">Remove All</span>
                                  </span>
                                </div>
                              )}
                            </div>
                          </Card.Body>
                        </Card>
                      </SplideSlide>
                    )
                )}
              </Splide>
            </Form.Group>

            <Form.Group className="boxed mb-2" controlId="formFile">
              <Form.Label>
                Upload Certification for the Professional Skill Mentioned (If Any)
              </Form.Label>
              <InputGroup className="boxed mb-2 position-relative">
                <InputGroup.Text id="basic-addon1">
                  <i className="fa fa-paperclip"></i>
                </InputGroup.Text>
                <Form.Control
                  type="file"
                  onChange={e => {
                    uploadRefImage(e.target.files[0])
                  }}
                />
              </InputGroup>
            </Form.Group>

            <Form.Check
              className="boxed my-4"
              type="checkbox"
              id="default-checkbox"
              label="Choose if you would like to receive Job/work notifications on Whatsapp/Email."
              onChange={e => setSelectNotification(e.target.value)}
            />
            <Row>
              <Col>
                <Button
                  type="button"
                  onClick={handleGoBack}
                  className="btn btn-primary btn-lg w-100 mx-1"
                >
                  <i
                    className="fa fa-chevron-left"
                    style={{ fontSize: '14px', paddingRight: '5px' }}
                  ></i>
                  Back
                </Button>
              </Col>
              <Col>
                <Button
                  type="submit"
                  className="btn btn-primary btn-lg w-100 mx-1"
                  disabled={fileUploaded}
                >
                  Done
                  <i
                    className="fa fa-chevron-right"
                    style={{ fontSize: '14px', paddingLeft: '5px' }}
                  ></i>
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth }) => {
  const { userData, role, fullName, ID, submitFormStep1 } = auth

  return {
    userData,
    role,
    fullName,
    ID,
    submitFormStep1
  }
}

const mapDispatchToProps = {
  updateRegistrationStep
}

export default connect(mapStateToProps, mapDispatchToProps)(RegFormStep2)
