import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import LocationModal from '../Sidebar/LocationModal'
import { Form, Button, Offcanvas } from 'react-bootstrap'
import { Link, useNavigate } from 'react-router-dom'
import { logout, setCity } from '../../../features/auth/authSlice'
import Logo from '../../images/ScopeHaiLogo-two-color.png'
import UserProfile from '../../images/user-profile.webp'
import {
  FaDigitalTachograph,
  FaCertificate,
  FaBriefcase,
  FaInfo
} from 'react-icons/fa'
import { showLocationModal } from '../../../features/popup/locationModalSlice'

const Header = ({
  logout,
  userData,
  setCity,
  citySelect,
  allCities,
  showLocationModal,
  selectedCity,
  role,
  fullName,
  ID
}) => {
  let navigate = useNavigate()
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  // console.log(ID)

  // const handleDropdownCityChange = (e) => {
  //   const selectedCity = allCities.filter ((item) => item.city === e.target.value)
  //   setCity({
  //       'City': selectedCity[0]
  //   })
  //   localStorage.setItem ('selectedCity', JSON.stringify (selectedCity[0]))
  // };
  // useEffect (() => {
  //   if (localStorage.getItem('selectedCity')){
  //       const getCity = JSON.parse(localStorage.getItem('selectedCity'))
  //       setCity({
  //           'City': getCity
  //       })
  //   }
  // }, [])

  const handleUserProfile = () => {
    navigate('/userProfile')
  }

  const handleLogout = e => {
    localStorage.clear()
    window.location.href = '/'
  }

  const navigateTo = URL => {
    navigate(URL)
    // document.getElementById('modalCloseButton').click()
  }

  const selectLocation = () => {
    showLocationModal({ locationModalVisible: true })
  }

  return (
    <div>
      <LocationModal></LocationModal>
      <div class="appHeader bg-white scrolled">
        <div class="left">
          <a
            href="#"
            className="headerButton fill-primary"
            data-bs-toggle="offcanvas"
            data-bs-target="#sidebarPanel"
            onClick={handleShow}
          >
            <ion-icon name="menu-outline"></ion-icon>
          </a>
        </div>
        <div class="pageTitle row w-100 d-flex justify-content-center p-0">
          <div className="d-flex justify-content-center p-0">
            <a href="#!" className="headerButton p-0">
              <img
                src={Logo}
                className="img-fluid"
                alt="Brand"
                width="220x220"
              />
            </a>
          </div>
        </div>
        <div class="right">
          <a
            href="#"
            class="headerButton toggle-searchbox fill-primary"
            onClick={selectLocation}
          >
            <ion-icon name="locate"></ion-icon>
            <p className="m-0 ms-1 text-primary d-sm-block d-none">
              {' '}
              {selectedCity}
              {/* <Form.Select className='selectLocation'>
                <option value="Mumbai">Mumbai</option>
                <option value="Delhi">Delhi</option>
                <option value="Bangalore">Bangalore</option>
                <option value="Kolkata">Kolkata</option>
                <option value="Chennai">Chennai</option>
                <option value="Hyderabad">Hyderabad</option>
                {allCities.map((item) => {
                    return <option value={item.city} selected={item.city === citySelect.city}>{item.shahar}</option>
                })}
              </Form.Select> */}
            </p>
          </a>
        </div>
      </div>

      <Offcanvas show={show} onHide={handleClose}>
        <Offcanvas.Header className="profileBox" closeButton>
          <Offcanvas.Title className="d-flex">
            <div class="image-wrapper">
              <img src={UserProfile} alt="User" class="imaged rounded" />
            </div>
            <div className="in">
              <strong>{fullName}</strong>
              <div className="text-muted pt-1">
                {role === 'Candidate' ? (
                  <p
                    className="edit-profile-btn m-0 text-muted"
                    onClick={() => navigateTo('/candidate-profile')}
                  >
                    View Profile
                  </p>
                ) : role === 'Employer' ? (
                  <p
                    className="edit-profile-btn m-0 text-muted"
                    onClick={() => navigateTo('/employer-profile')}
                  >
                    View Profile
                  </p>
                ) : (
                  role
                )}
              </div>
            </div>
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className="modal-body p-0">
            <ul className="listview flush transparent no-line image-listview">
              {role === 'Candidate' && (
                <>
                  <li>
                    <div
                      onClick={() => navigateTo('/dashboard')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="analytics"></ion-icon>
                      </div>
                      <div className="in">Dashboard</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/certification-and-trainings')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="ribbon"></ion-icon>
                      </div>
                      <div className="in">Certification & Trainings</div>
                    </div>
                  </li>
                  <li>
                    <div onClick={() => navigateTo('/jobs')} className="item">
                      <div className="icon-box fill-primary">
                        <ion-icon name="briefcase"></ion-icon>
                      </div>
                      <div className="in">Jobs</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/coming-soon')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="id-card"></ion-icon>
                      </div>
                      <div className="in">Build Career</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/placement-drives')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="albums"></ion-icon>
                      </div>
                      <div className="in">Placement Drives</div>
                    </div>
                  </li>
                </>
              )}
              {role === 'Employer' && (
                <>
                  <li>
                    <div
                      onClick={() => navigateTo('/employer-dashboard')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="analytics"></ion-icon>
                      </div>
                      <div className="in">Employer Dashboard</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/emp-placement-drives')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="albums"></ion-icon>
                      </div>
                      <div className="in">Placement Drives</div>
                    </div>
                  </li>
                  <li>
                    <div onClick={() => navigateTo('/jobs')} className="item">
                      <div className="icon-box fill-primary">
                        <ion-icon name="briefcase"></ion-icon>
                      </div>
                      <div className="in">Hirings</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/emp-candidate-list')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="search"></ion-icon>
                      </div>
                      <div className="in">Search Candidate</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/candidate-profile')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="search"></ion-icon>
                      </div>
                      <div className="in">Candidate Profile</div>
                    </div>
                  </li>
                </>
              )}
              {role === 'Admin' && (
                <>
                  <li>
                    <div
                      onClick={() => navigateTo('/admin-dashboard')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="analytics"></ion-icon>
                      </div>
                      <div className="in">Dashboard</div>
                    </div>
                  </li>
                  <li>
                    <div onClick={() => navigateTo('/vsp')} className="item">
                      <div className="icon-box fill-primary">
                        <ion-icon name="checkmark-done-circle-outline"></ion-icon>
                      </div>
                      <div className="in">VSP Applicants</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/cohort-applicant')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="checkmark-done-circle-outline"></ion-icon>
                      </div>
                      <div className="in">Cohort Applicants</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/payments')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="checkmark-done-circle-outline"></ion-icon>
                      </div>
                      <div className="in">payments</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/admin-certifications')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="ribbon"></ion-icon>
                      </div>
                      <div className="in">Certification & Trainings</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/admin-jobs')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="briefcase"></ion-icon>
                      </div>
                      <div className="in">Jobs</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/admin-placement-drives')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="albums"></ion-icon>
                      </div>
                      <div className="in">Placement Drives</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/admin-users')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="people"></ion-icon>
                      </div>
                      <div className="in">Users</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/coming-soon')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="stats-chart"></ion-icon>
                      </div>
                      <div className="in">Reports</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/search-url')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="link-outline"></ion-icon>
                      </div>
                      <div className="in">Search url</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/scrapper')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="link-outline"></ion-icon>
                      </div>
                      <div className="in">Scrapper</div>
                    </div>
                  </li>
                </>
              )}
              {role === 'hr' && (
                <>
                  <li>
                    <div onClick={() => navigateTo('/vsp')} className="item">
                      <div className="icon-box fill-primary">
                        <ion-icon name="checkmark-done-circle-outline"></ion-icon>
                      </div>
                      <div className="in">VSP Applicants</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/vps-verification')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="link-outline"></ion-icon>
                      </div>
                      <div className="in">VSP Verification</div>
                    </div>
                  </li>
                  <li>
                    <div
                      onClick={() => navigateTo('/cslr-candidate-list')}
                      className="item"
                    >
                      <div className="icon-box fill-primary">
                        <ion-icon name="list-outline"></ion-icon>
                      </div>
                      <div className="in">Candidate List</div>
                    </div>
                  </li>
                </>
              )}
              {role === 'Counsellor' && (
                <li>
                  <div
                    onClick={() => navigateTo('/cslr-candidate-list')}
                    className="item"
                  >
                    <div className="icon-box fill-primary">
                      <ion-icon name="list-outline"></ion-icon>
                    </div>
                    <div className="in">Candidate List</div>
                  </div>
                </li>
              )}
            </ul>
          </div>
          <div className="sidebar-buttons appBottomMenu bg-primary text-light">
            <Button
              variant="primary"
              onClick={() => navigateTo('/dashboard')}
              className="item"
            >
              <div className="col">
                <ion-icon name="headset-outline"></ion-icon>
                <strong>Support</strong>
              </div>
            </Button>
            <Button variant="primary" className="item" onClick={handleLogout}>
              <div className="col">
                <ion-icon className="m-0" name="log-out-outline"></ion-icon>
                <strong>Logout</strong>
              </div>
            </Button>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  )
}

const mapStateToProps = ({ auth, locationModal }) => {
  const { userData, citySelect, allCities, role, fullName, ID } = auth
  const { selectedCity } = locationModal

  return {
    userData,
    citySelect,
    allCities,
    selectedCity,
    role,
    fullName,
    ID
  }
}

const mapDispatchToProps = {
  logout,
  setCity,
  showLocationModal
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
