import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Row, Col, Carousel, Card, Button } from 'react-bootstrap';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import moment from 'moment';
import { connect } from 'react-redux';
import EmpPlacementDrives from './EmpPlacementDrives';
import { getAllJobs, fetchCertificationCards, fetchPlacementDrives, GetAllApplicationsOfDrive, getAllCourseApplications, getAllFakeJobs, GetCandidateByID, GetVspStatusByID } from '../../features/constants/apis';
import CertificationCardComp from './Cards/CertificationCardComp';
import PlacementDriveCard from './Cards/PlacementDriveCard';
import JobListCard from './JobListCard';
import GetVspModal from './Modals/GetVspModal';

function Dashboard({ userData, role, fullName, authToken, ID }) {

    const [allJobs, setAllJobs] = useState([]);
    const [allFakeJobs, setAllFakeJobs] = useState([]);
    const [placementDrives, setPlacementDrives] = useState([]);
    const [jobApplicationsCount, setJobApplicationsCount] = useState(0);
    const [candidiateJobApplicationsCount, setCandidateJobApplicationsCount] = useState(0);
    const [certificationCards, setCertificationCards] = useState([]);
    const [courseApplications, setCourseApplications] = useState([]);
    const [showGetVspModal, setShowGetVspModal] = useState(false);
    const [userInfo, setUserInfo] = useState();
    const [openToStatus, setOpenToStatus] = useState();
    const [progressCount, setProgressCount] = useState();
    const [userVspStatus, setUserVspStatus] = useState();
    const handleCloseGetVspModal = () => {
        setShowGetVspModal(false);
    };
    console.log("user Info: ", userInfo);
    useEffect(() => {
        const fetchData = async () => {
            console.log("useEffect fun called for get by ID and VSP: ")
            const data = await GetCandidateByID(ID, authToken);
            // console.log("user info :", data.data);
            setUserInfo(data.data);
            setOpenToStatus(data.data.JoiningStatus);
            setProgressCount(data.data?.ProfileProgress);
            console.log("ProfileProgress count: ", data?.data?.ProfileProgress);
            console.log("joining status: ", data.data.JoiningStatus);

            const vspStatus = await GetVspStatusByID(ID, authToken);
            console.log("vsp status: ", vspStatus);
            setUserVspStatus(vspStatus.vspStatus);
        }
        fetchData();
    }, [authToken])

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchCertificationCards(authToken);
            setCertificationCards(data);
        }
        fetchData();
    }, [authToken])

    useEffect(() => {
        const fetchData = async () => {
            const data = await fetchPlacementDrives(authToken);
            setPlacementDrives(data.data);
        }
        fetchData();
    }, [authToken])

    const navigate = useNavigate();
    const navigateTo = (URL) => {
        navigate(URL)
    }

    const handleViewDriveDetails = (drive) => {
        // setSelectedDrive(drive);
        navigate(`/emp-placement-drive-details?drive=${drive}`)
    }

    const calculateCountdown = (fromDate) => {
        const now = moment();
        const fromDateMoment = moment(fromDate);
        const diffDays = fromDateMoment.diff(now, 'days');
        const formattedDate = moment(fromDate).format('DD MMMM YYYY');
        return { diffDays, formattedDate };
    };

    useEffect(() => {
        const fetchAllJobs = async () => {
            const jobsData = await getAllFakeJobs(authToken);
            setAllFakeJobs(jobsData.data)
        }
        getAllFakeJobs()

    }, [])



    // Job Applications of the Candidates
    useEffect(() => {
        const fetchPDApplications = async () => {
            const data = await GetAllApplicationsOfDrive()
            setJobApplicationsCount(data.length)
            const filteredApplication = data.filter(application => application.CandidateID === ID)
            setCandidateJobApplicationsCount(filteredApplication.length);
        }
        fetchPDApplications()
    }, [ID])

    // Course Applications
    useEffect(() => {
        const fetchCourseApplications = async () => {
            const data = await getAllCourseApplications(authToken)
            setCourseApplications(data.data)
        }
        fetchCourseApplications()
    }, [])

    return (
        <div id="appCapsule" className='Dashboard'>
            {/* Dashboard Count View Start */}
            <div className='section full py-4'>
                <div className='container'>
                    {/* <h1 className="title text-light">Dashboard</h1> */}
                    <div className="row">
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="person-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">0</h5>
                                        <h6 className="card-subtitle">Total Visitors</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="bookmarks-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">0</h5>
                                        <h6 className="card-subtitle">Shortlisted</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="eye-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">NaN</h5>
                                        <h6 className="card-subtitle">Views</h6>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="checkmark-done-outline"></ion-icon>
                                    </div>
                                    <div className='order-sm-0'>
                                        <h5 className="card-title">{role === 'Candidate' ? candidiateJobApplicationsCount : jobApplicationsCount}</h5>
                                        <h6 className="card-subtitle">Applied Jobs</h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* /////////////////////////////////////////////////// joining status */}
                        <div className="col-lg-3 col-6 mb-2">
                            <div className="card">
                                <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                    <div className='db-bg-icon order-sm-1'>
                                        <ion-icon size="large" name="toggle-outline" ></ion-icon>
                                    </div>
                                    {
                                        openToStatus === 'join' ?
                                            <>
                                                <div className='order-sm-0'>
                                                    <h5 className="card-title">Join</h5>
                                                    <h6 className="card-subtitle">New Opportunity</h6>
                                                </div>
                                            </> : <></>
                                    }

                                    {
                                        openToStatus === 'switch' ?
                                            <>
                                                <div className='order-sm-0'>
                                                    <h5 className="card-title">Switch</h5>
                                                    <h6 className="card-subtitle">New Opportunity</h6>
                                                </div>
                                            </> : <></>
                                    }

                                    {
                                        openToStatus === 'null' || openToStatus == null || openToStatus == '' ?
                                            <>
                                                <div className='ready-box order-sm-0'>
                                                    <h5 className="card-title">Ready</h5>
                                                    <h6 className="card-subtitle">Ready For New Opportunity?</h6>
                                                </div>
                                            </> : <></>
                                    }
                                </div>
                            </div>
                        </div>



                        {role === 'Admin' &&
                            <div className="col-lg-3 col-6 mb-2">
                                <Link to='/course-applications'>
                                    <div className="card">
                                        <div className="card-body d-flex justify-content-between align-items-center flex-sm-col">
                                            <div className='db-bg-icon order-sm-1'>
                                                <ion-icon size="large" name="people-outline"></ion-icon>
                                            </div>
                                            <div className='order-sm-0'>
                                                <h5 className="card-title">{courseApplications && courseApplications.length}</h5>
                                                <h6 className="card-subtitle">Course Applications</h6>
                                            </div>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                        }

                    </div>
                </div>
            </div>
            {/* Dashboard Count View End */}
            {/* User Profile Start */}
            <div className="section full mt-2 mb-2 pt-4 Dashboard">
                <div className='container'>
                    <Row>
                        <Col md={9} sm={12}>
                            <div>
                                <h1 className="title">Hi, {fullName}</h1>
                                <h2 className='sub-title'>Welcome to your Dashboard !</h2>
                                {/* <p>Here is how your profile is performing on Scopehai Talent platform.</p> */}
                            </div>
                        </Col>
                        <Col md={3} sm={12} className='d-flex justify-content-center align-items-center'>
                            {role === "Candidate" && (
                                < button
                                    type="button"
                                    class="btn btn-primary"
                                    onClick={() => navigateTo('/candidate-profile')}>
                                    {progressCount === 100 ? 'Edit Profile' : 'Complete Profile'}<i class="fa fa-arrow-right ms-1"></i>
                                </button>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col md={9} sm={12}>
                            <h2>Get a verified skill profile (VSP) to get higher chances of success</h2>
                            <h3>Verified skill profiles receive 70% more opportunities on Scopehai</h3>
                        </Col>
                        <Col md={3} sm={12} className='d-flex justify-content-center align-items-center'>
                            {role === "Candidate" &&
                                <button
                                    type="button"
                                    disabled={userVspStatus ? true : false}
                                    class="btn btn-outline-primary vsp-btn"
                                    onClick={() => setShowGetVspModal(true)}>
                                    {userVspStatus === true ? 'VSP Verified' : userVspStatus === false ? 'VSP Applied' : 'Get VSP'}
                                    {
                                        userVspStatus === true ? <img className='imaged w-20 ms-1 rounded' src='/static/media/Favicon_large-removebg-preview.8238d064.png'></img> : <i class="fa fa-arrow-right ms-1"></i>
                                    }
                                </button>
                            }
                        </Col>
                    </Row>
                    <GetVspModal
                        // onHide={() => setVspModalShow(false)}
                        vspStatus={userVspStatus}
                        show={showGetVspModal}
                        handleClose={handleCloseGetVspModal}
                    />
                </div>
            </div>
            {/* User Profile End */}
            {/* Certificates Slider Start */}
            <div className="section full mt-4 mb-5">
                <div className='container'>
                    <h2>Recommended training and placement programs</h2>
                    <h3>Here are the recommended training and placement programs for you.</h3>
                    <p>We have considered the data you have submitted with us and our smart algorithms have come up with some upskilling options for you.</p>
                    <Splide
                        options={{
                            rewind: false,
                            gap: '1rem',
                            perMove: 1,
                            perPage: 3,
                            type: 'loop',
                            autoplay: true,
                            interval: 3000,
                            breakpoints: {
                                875: {
                                    perPage: 2
                                },
                                585: {
                                    perPage: 1
                                }
                            }
                        }}
                        aria-label="React Splide Example">
                        {certificationCards.map((v, k) => {
                            return (
                                <SplideSlide>
                                    <CertificationCardComp v={v}></CertificationCardComp>
                                </SplideSlide>
                            )
                        })}
                    </Splide>
                </div>
            </div>
            {/* Certificates Slider End */}
            {/* Placement Drives Start */}
            <div className="section full mt-2 mb-4 py-4">
                <div className='container'>
                    <h2>Current Placement Drives</h2>
                    {/* <Row xs={1} md={2} className="g-4"> */}
                    <Splide
                        options={{
                            rewind: false,
                            gap: '.5rem',
                            perMove: 1,
                            perPage: 2,
                            type: 'loop',
                            autoplay: true,
                            interval: 3000,
                            breakpoints: {
                                875: {
                                    perPage: 2
                                },
                                585: {
                                    perPage: 1
                                }
                            }
                        }}
                        aria-label="React Splide Example">
                        {placementDrives.map((drive, idx) => (
                            <SplideSlide>
                                <>
                                    <PlacementDriveCard key={idx} drive={drive} handleViewDriveDetails={handleViewDriveDetails} />
                                </>
                            </SplideSlide>
                        ))}
                    </Splide>
                    {/* </Row> */}
                </div>
            </div>

            {/* <EmpPlacementDrives></EmpPlacementDrives> */}
            {/* Placement Drives End */}
            {/* Jobs List Start */}
            <div className="section full mt-4 mb-4">
                <div className='container'>
                    {/* <div className="section-title ps-0">Top jobs matching your profile</div> */}
                    <h2>Top jobs matching your profile</h2>
                    <p>We have considered your profile and the inputs given by you and have pulled some of the jobs from our recruitement partners.</p>
                    {/* <ul className="listview image-listview media">
                    { allJobs.map((job, idx) => (
                                <li className='my-2' key={idx}>
                                    <div className="item">
                                        <div className="imageWrapper in">
                                            <div className="in">
                                                <div>
                                                    <div className="text-muted">{job?.Type}</div>
                                                    {job?.Title}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="in">
                                            <div>
                                                <div className="text-muted">{job?.Location}</div>
                                                {job?.CtcFrom} - {job?.CtcTo} LPA
                                            </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                            <Button variant="primary">Apply</Button>
                                        </div>
                                    </div>
                                </li>)
                            )}
                    </ul> */}
                    <JobListCard allFakeJobs={allFakeJobs} />
                </div>
            </div>
            {/* Jobs List End */}
        </div >
    )
}

const mapStateToProps = ({ auth }) => {
    const { userData, role, fullName, ID, authToken } = auth

    return {
        userData,
        role,
        fullName,
        ID,
        authToken
    }
}

export default connect(mapStateToProps)(Dashboard)